import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useFetch, usePermissions } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { AutoCompleteComponentBP as AutoCompleteBP, DialogChangeLogs, CustomToolbar } from '../../components';
import { isTomorrow } from 'date-fns';
import { Button } from '@mui/material';
import AutoComplete from './components/AutoComplete'
import { getParam, checkObjectKey, dateTimeFormatter } from './../../utils/utils'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { backend_url } from '../../settings'

export default function SupportChangeLogs() {

  const { t } = useTranslation('translation')
  const { obj, childObj } = usePermissions()
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose } = useUserPrefs();

  const [selectedLog, setSelectedLog] = useState(null);
  const [selectedValue, setSelectedValue] = useState({})
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({})
  const rows = [
    {
      field: 'BUSINESS_PARTNER',
      title: t("CHANGE_LOGS.TitleBP"),
      data: {
        required: true,
        headerName: t("CHANGE_LOGS.TitleBP"),
        multiple: false,
        isTreeChar: true,
        field: 'BP_NR',
        keysForInput: ['BP_NR', 'NAME1'],
        url: backend_url.bp_list,
        valueParser: (value) => {
          return {
            BP_NR: value.BUSINESS_PARTNER,
            BP_ROLE: value.BP_ROLE,
            field: 'BUSINESS_PARTNER'
          }
        },
        valueFormatted: (value) => {
          return [value]
        },
      }
    },
    {
      field: 'MATERIAL',
      title: t("CHANGE_LOGS.TitleMat"),
      data: {
        headerName: t("CHANGE_LOGS.TitleMat"),
        url: backend_url.mat_list,
        multiple: false,
        isTreeChar: true,
        field: 'MATERIAL_NR',
        keysForInput: ['DATABASE_TABLE', 'MATERIAL_NR', 'MATERIAL_DESCRIPTION'],
        valueParser: (value) => {
          return {
            MATERIAL_NR: value.MATERIAL_NR,
            field: 'MATERIAL_NR'
          }
        },
        valueFormatted: (value) => {
          return [value]
        },
      }
    },
    {
      field: 'MATERIAL_CLASSIFICATION',
      title: t('CHANGE_LOGS.TitleMatClass'),
      data: {
        headerName: t('CHANGE_LOGS.TitleMatClass'),
        title: 'Material No.',
        url: backend_url.mat_class_list,
        multiple: false,
        isTreeChar: true,
        field: 'key',
        keysForInput: ['DATABASE_TABLE', 'KEY'],
        valueParser: (value) => {
          return {
            KEY: value.KEY,
            field: 'KEY'
          }
        },
        valueFormatted: (value) => {
          return [value]
        },
      }
    },
    {
      field: 'ACCRUAL_OBJECT',
      title: t("CHANGE_LOGS.TitleAO"),
      data: {
        headerName: t("CHANGE_LOGS.TitleAO"),
        title: 'AO ID',
        url: backend_url.acrual_object_bp,
        multiple: false,
        isTreeChar: true,
        field: 'AO_ID',
        keysForInput: ['AO_ID'],
        valueParser: (value) => {
          return {
            AO_ID: value.AO_ID,
            LEVEL: value.LEVEL,
            field: 'AO_ID'
          }
        },
      }
    },
  ]

  const logs = {
    BUSINESS_PARTNER: {
      title: t("CHANGE_LOGS.TitleBP"),
      value: 'BP_NR',
      url: backend_url.bp_list,
      endpoint: [{ field: 'BP_ROLE', name: 'BP_ROLE' }, { field: 'BP_NR', name: 'BP_NR' }],
      endpointName: '/change_log',
      skip: 100,
      limit: 100,
      isTreeChar: true,
      checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
      prevHide: {
        "EXT_USER_ID": false,
        "DOC_NUMBER": false
      },
      columns: [
        {
          field: 'TIMESTAMP',
          headerName: t("CHANGE_LOGS.ChangeTime"),
          flex: 1,
          minWidth: 150,
          renderCell: ({ value }) => {
            if (value) {
              return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            return value
          },
        },
        { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
        { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
        {
          field: 'BUSINESS_AREA_PRIMARY_KEY',
          headerName: t("CHANGE_LOGS.PrimaryKey"),
          minWidth: 220,
          renderCell: ({ value }) => {

            const lines = Object.entries(value).map(it => `${it[0]}: ${it[1]}`);
            return (
              <div style={{ whiteSpace: 'pre-line' }}>
                {lines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            );
          },
        },
        { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
        { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
        { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
        {
          field: 'EXT_USER_ID',
          headerName: t("CHANGE_LOGS.ExtUserId"),
          flex: 1,
        },
        { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
      ]
    },
    MATERIAL: {
      title: t("CHANGE_LOGS.TitleMat"),
      value: 'MATERIAL_NR',
      url: backend_url.mat_status,
      endpoint: [{ field: 'MATERIAL_NR', name: 'MATERIAL_NR' }],
      endpointName: '/change_log',
      checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
      isTreeChar: false,
      skip: 100,
      limit: 100,
      prevHide: {
        "EXT_USER_ID": false,
        "DOC_NUMBER": false
      },
      columns: [
        {
          field: 'TIMESTAMP',
          headerName: t("CHANGE_LOGS.ChangeTime"),
          flex: 1,
          minWidth: 150,
          renderCell: ({ value }) => {
            if (value) {
              return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            return value
          },
        },
        { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
        { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
        {
          field: 'BUSINESS_AREA_PRIMARY_KEY',
          headerName: t("CHANGE_LOGS.PrimaryKey"),
          minWidth: 220,
          renderCell: ({ value }) => {

            const lines = Object.entries(value).map(it => `${it[0]}: ${it[1]}`);
            return (
              <div style={{ whiteSpace: 'pre-line' }}>
                {lines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            );
          },
        },
        { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
        { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
        { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
        {
          field: 'EXT_USER_ID',
          headerName: t("CHANGE_LOGS.ExtUserId"),
          flex: 1,
        },
        { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
      ]
    },
    MATERIAL_CLASSIFICATION: {
      title: t("CHANGE_LOGS.TitleMatClass"),
      value: 'KEY',
      url: backend_url.mat_class_change_log,
      endpoint: [{ field: 'KEY', name: 'KEY' }],
      endpointName: '/change_log',
      checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
      isTreeChar: false,
      skip: 100,
      limit: 100,
      prevHide: {
        "EXT_USER_ID": false,
        "DOC_NUMBER": false
      },
      columns: [
        {
          field: 'TIMESTAMP',
          headerName: t("CHANGE_LOGS.ChangeTime"),
          flex: 1,
          minWidth: 150,
          renderCell: ({ value }) => {
            if (value) {
              return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            return value
          },
        },
        { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
        { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
        {
          field: 'BUSINESS_AREA_PRIMARY_KEY',
          headerName: t("CHANGE_LOGS.PrimaryKey"),
          minWidth: 220,
          renderCell: ({ value }) => {

            const lines = Object.entries(value).map(it => `${it[0]}: ${it[1]}`);
            return (
              <div style={{ whiteSpace: 'pre-line' }}>
                {lines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            );
          },
        },
        { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
        { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
        { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
        {
          field: 'EXT_USER_ID',
          headerName: t("CHANGE_LOGS.ExtUserId"),
          flex: 1,
        },
        { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
      ]
    },
    ACCRUAL_OBJECT: {
      title: t("CHANGE_LOGS.TitleAO"),
      value: 'AO_ID',
      url: backend_url.acrual_object_bp,
      endpoint: [{ field: 'AO_ID', name: 'AO_ID' }],
      endpointName: '/run_plan/changelog', 
      checkToHide: [],
      prevHide: {},
      isTreeChar: true,
      skip: 3,
      limit: 3,
      isLevel: true,
      columns: [
        {
          field: 'Change_Time',
          headerName: t("CHANGE_LOGS.ChangeTime"),
          flex: 1,
          minWidth: 150,
          renderCell: ({ value }) => {
            if (value) {
              return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            return value
          },
        },
        { field: 'Area', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
        { field: 'Attribute', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
        {
          field: 'Business_Area/Primary_key',
          headerName: t("CHANGE_LOGS.PrimaryKey"),
          minWidth: 220,
        },
        {
          field: 'Action',
          headerName: t("CHANGE_LOGS.Action"),
          flex: 1,
          renderCell: ({ value }) => {
            switch (value) {
              case "updated":
                return (
                  <div style={{ color: "#f66d38" }}>
                    {value}
                  </div>
                )
              case "deleted":
                return (
                  <div style={{ color: "#ff0000" }}>
                    {value}
                  </div>
                )
              case "added":
                return (
                  <div style={{ color: "#00a651" }}>
                    {value}
                  </div>
                )
              default:
                return (
                  <div style={{ color: "#000" }}>
                    {value}
                  </div>
                )
            }
          }
        },
        { field: 'Old_value', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
        { field: 'New_value', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
        { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 }, 
        { field: 'Ext_User_ID', headerName: t("CHANGE_LOGS.ExtUserId"), flex: 1 },
      ]
    },
  }


  const handleChange = (event) => {
    setSelectedLog(event.target.value)
    setSelectedValue({})
  }

  const handleInput = (event) => {
    setSelectedValue(
      {
        field: 'AO_ID',
        AO_ID: event.target.value
      })
  }


  const DropDownMenu = (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{t("CHANGE_LOGS.TitleDrobDown")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={''}
          label={t("CHANGE_LOGS.TitleDrobDown")}
          onChange={handleChange}
        >
          <MenuItem disabled value={""}></MenuItem>
          {rows.map((it, id) => <MenuItem key={id} value={it.field}>{it.title}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
  const onChangeHandler = (data) => {
    setSelectedValue(data)
  }

  const handleClick = () => {
    setOpen(true)
  }

  const onClose = () => setOpen(false)

  const selectedNumber = (value) => {
    const item = rows.find(it => it.field == value)

    switch (item.field) {
      case 'BUSINESS_PARTNER':
        return (
          <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete'>
            <div>
              <FormControl size="small">
                {/* <AutoCompleteBP onChangeHandler={onChangeHandler} data={item.data} /> */}
                <AutoComplete onChangeHandler={onChangeHandler} data={item.data} />
              </FormControl>
            </div>
          </div>
        )
      case 'MATERIAL':
        return (
          <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete'>
            <div>
              <FormControl size="small">
                <AutoComplete onChangeHandler={onChangeHandler} data={item.data} />
              </FormControl>
            </div>
          </div>
        )
      case 'MATERIAL_CLASSIFICATION':
        return (
            <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete'>
              <div>
                <FormControl size="small">
                  <AutoComplete onChangeHandler={onChangeHandler} data={item.data}/>
                </FormControl>
              </div>
            </div>
        )
      case 'ACCRUAL_OBJECT':
        return (
          <div className='bpx-modal-add-data'>
            <TextField
              size="small"
              value={selectedValue.AO_ID || ''}
              label={selectedLog && selectedLog.title || ''}
              className='bpx-modal-add-data-field'
              required={true}
              onChange={handleInput}
            />
          </div>
        )
    }
  }
  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t("Breadcrumb.support"), link: '' },
    { label: t("Breadcrumb.change_logs"), link: '/support/changelogs' },
  ]

  return (
    <>
      <div className="tableContainer">
        <CustomToolbar bread={objBreadcrumb} title={t("Config.TITLE.MAIN")} />
        <Box sx={{ maxWidth: '350px' }} className='bpx-support-changelog'>
          <Box>
            {DropDownMenu}
          </Box>
          <Box sx={{ margin: '15px 0' }}>
            {selectedLog && selectedNumber(selectedLog)}
          </Box>
          <Box sx={{ margin: '15px 0', display: 'flex', justifyContent: 'right' }}>
            {
              !!Object.keys(selectedValue).length &&
              <Button onClick={handleClick} variant="contained" color="primary">
                Show logs
              </Button>
            }
          </Box>

          <DialogChangeLogs open={open} onCloseHandle={onClose} data={selectedValue} dataLogs={selectedLog ? logs[selectedLog] : logs['BUSINESS_PARTNER']} />
        </Box>
      </div>
    </>
  )
}