import React, { useState, useEffect, useCallback } from "react"
import { DataGridPro, GridOverlay, useGridApiRef } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { backend_url } from "../../settings"
import { getParam, numberFormatter, dateTimeFormatter, applyFilters, checkSize } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { CustomToolbar } from "../../components"
import { Link } from "react-router-dom"
import { CustomLinkTable } from "../../components"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useFetch, usePermissions } from "../../hooks"
import { AccrualAdjustmentModal } from './AccrualAdjustmentModal'
import { DialogAdjustments } from "../../components/DialogStatus/DialogAdjustments"
import { AdjustmentResultsModal } from "../AccrualAdjustments/AdjustmentResultsModal"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import SnackbarUtils from '../../utils/SnackbarUtils'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReportIcon from '@mui/icons-material/Report';


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export const AccrualAdjustmentsList = () => {

    const apiRef = useGridApiRef()

    const { t } = useTranslation("translation")
    const value_bu = localStorage.getItem("bu_bpx")
    const tokenBpxRequest = localStorage.getItem("token_bpx")

    const request = useFetch()

    const { obj, childObj } = usePermissions()

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)

    const [exportQuery, setExportQuery] = useState("")
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogType, setDialogType] = useState("")
    const [newAdjustmentOpen, setNewAdjustmentOpen] = useState(false)
    const [planAccount, setPlanAccount] = useState()
    const [acAd, setAcAd] = useState()
    const [openResultsModal, setOpenResultsModal] = useState(false)
    const [eventId1, setEventId1] = useState()
    const [eventId2, setEventId2] = useState()
    const [isSimulation, setIsSimulation] = useState()
    const [aoId, setAoId] = useState()
    const [budgets, setBudgets] = useState({PP1_AMOUNT: null, PP2_AMOUNT: null})
    const [updateRows, setUpdateRows] = useState(false)
    const [status, setStatus] = useState()
    const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
    const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order&&prefsData.reports_column_order.Adjustments || []);
    const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Adjustments || 'm');

    const columnVisibility = {};
    if(prefsData.reports_column_visibilty.Adjustments){
        prefsData.reports_column_visibilty.Adjustments.forEach(it=>columnVisibility[it]=false);
    }


    const saveColumnVisibility = (data)=>{
      saveUserPrefs({
        ...prefsData,
        reports_column_visibilty:{
          ...prefsData.reports_column_visibilty,
          Adjustments: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
        }
      })
    }

    const [filter, setFilter] = useState([
      {
          endpoint: backend_url.adjustments_filter_values,
          queryKeyToTyping: "AC_AD_ID",
          title: t("Adjustments.ADJUSTMENT_ID_TITLE"),
          inputText: t("Adjustments.AcAd_ID"),
          firstKeyOption: "AC_AD_ID",
          secondKeyOption: "",
          valueFilter: applyFilters("AC_AD_ID", "adjustmentList"),
          changingArray: [],
          formatField: "select",
      },
      {
          endpoint: backend_url.adjustments_filter_values,
          queryKeyToTyping: "AO_ID",
          title: t("Adjustments.AO_ID_TITLE"),
          inputText: t("Adjustments.AO_ID_INPUT"),
          firstKeyOption: "AO_ID",
          secondKeyOption: "",
          valueFilter: applyFilters("AO_ID", "adjustmentList"),
          changingArray: [],
          atLeastThreeLetter: true,
          formatField: "select",
      },
      {
        endpoint: backend_url.adjustments_filter_values,
        queryKeyToTyping: "ACTIVITY_ID",
        title: t("Adjustments.ACTIVITY_ID_TITLE"),
        inputText: t("Adjustments.ACTIVITY_ID_INPUT"),
        firstKeyOption: "ACTIVITY_ID",
        secondKeyOption: "",
        valueFilter: applyFilters("ACTIVITY_ID", "adjustmentList"),
        changingArray: [],
        atLeastThreeLetter: true,
        formatField: "select",
      },
      {
          endpoint: backend_url.adjustments_filter_values,
          queryKeyToTyping: "PLAN_ACCOUNT",
          title: t("Adjustments.PLAN_ACCOUNT_TITLE"),
          inputText: t("Adjustments.PLAN_ACCOUNT_INPUT"),
          firstKeyOption: "PLAN_ACCOUNT_BP_NR",
          secondKeyOption: "PLAN_ACCOUNT_NAME",
          valueFilter: applyFilters("PLAN_ACCOUNT_BP_NR", "adjustmentList"),
          changingArray: [],
          formatField: "select",
      },
      {
          endpoint: backend_url.adjustments_filter_values,
          firstKeyOption: "PP1_DATE",
          title: t("Adjustments.POSTING_DATE_FROM"),
          valueFilter: applyFilters("PP1_DATE", "adjustmentList"),
          formatField: "date"
      },
      {
          endpoint: backend_url.adjustments_filter_values,
          firstKeyOption: "PP2_DATE",
          title: t("Adjustments.POSTING_DATE_TO"),
          valueFilter: applyFilters("PP2_DATE", "adjustmentList"),
          formatField: "date"
      },
    ])

    const changeFilterValues = (state) => {
      setFilter(state)
      setRows([])
      setPage(0)
    }

    const addLoadedRows = useCallback((items, filters=true) => {
        const changeIdRow = items.map((d, i) => {
            return {
              id: d.AC_AD_ID + "_" + d.AO_ID + "_" + d.AC_AD_VERSION + "_" + d.AC_AD_STATUS + "_" + i,
              PLAN_ACCOUNT: d.PLAN_ACCOUNT_BP_NR + ' - ' + d.PLAN_ACCOUNT_NAME,
              ...d,
            }
        })

        setRows((old) => {
            if (filters)
              return [...old, ...changeIdRow]
            else
              return changeIdRow
        })

    }, [])


    useEffect(() => {

      setLoading(true)

      const queryParams = {
        business_unit: value_bu,
        limit: 100,
        skip: page,
      }

      filter.map((key) => {
        const { valueFilter, firstKeyOption, formatField } = key

        switch (formatField) {
          case "select":
            if (valueFilter.length) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          case "date":
            if (valueFilter) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          default:
            return null
        }
      })

      let cachedfilters = localStorage.getItem("filters")
      let filterObj = {
          adjustmentList: queryParams
      }
      if (cachedfilters && Object.keys(cachedfilters).length) { 
          filterObj = JSON.parse(cachedfilters)
          filterObj["adjustmentList"] = queryParams
      }
      localStorage.setItem("filters", JSON.stringify(filterObj))

      const queryString = getParam(queryParams).toString()

      setExportQuery(queryString)

      request.fetchData(
        `${backend_url.accrual_adjustment}?${queryString}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
          setLoading(false)
          addLoadedRows(data)
          setCount(data.length)
      })

    }, [filter, page])

    useEffect(() => {
        return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
            silentUpdateUserPrefs()
        })
    }, [apiRef])

    useEffect(() => {
        if (exportQuery) {
            request.fetchData(
                `${backend_url.accrual_adjustment}?${exportQuery}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(data => {
                setLoading(false)
                addLoadedRows(data, false)
                setCount(data.length)
            })
            .catch((e) => {})
        }
    }, [updateRows])


    const handleOnRowsScrollEnd = (params) => {
      if (params.viewportPageSize&&!loading) {
        if (count >= 100) {
          return setPage(page + 100)
        } else {
          return null
        }
      }
    }

    const handleOpenDialog = (dialogType, planAccount, acAd) => {
        setDialogType(dialogType)
        setPlanAccount(planAccount)
        setAcAd(acAd)
        setDialogOpen(true)
    }

    const handleOpenResultsModal = (acAd, eventId1, eventId2, planAccount, simulation, aoId=null, budget1 = null, budget2 = null, status="") => {
        setAcAd(acAd)
        setPlanAccount(planAccount)
        setIsSimulation(simulation)
        setAoId(aoId)
        setBudgets({PP1_AMOUNT: budget1, PP2_AMOUNT: budget2})
        setEventId1(eventId1)
        setEventId2(eventId2)
        setStatus(status)
        setOpenResultsModal(true)
    }

    const handleAction = (action, acAd) => {
        var url = ""
        var data = null
        var method = "POST"
        var message = "The execution has been started"
        if (action === "cancel") {
            url = `${backend_url.accrual_adjustment}/update-status/${acAd}?business_unit=${value_bu}`
            data = JSON.stringify("Canceled")
            method = "PATCH"
            message = "Adjustment stutus set to canceled"
        } else {
            url = `${backend_url.accrual_adjustment}/${action}/${acAd}?business_unit=${value_bu}`
        }
        request.fetchData(
            url,
            method,
            data,
            false,
            tokenBpxRequest,
            false,
            false,
            false,
            true
        ).then(data => {
            if (data) {
                if (action !== "cancel") {
                    url = `${backend_url.accrual_adjustment}/update-status/${acAd}?business_unit=${value_bu}`
                    data = JSON.stringify("In Progress")
                    method = "PATCH"
                    request.fetchData(
                        url,
                        method,
                        data,
                        false,
                        tokenBpxRequest,
                        false,
                        false,
                        false,
                        true
                    ).then(data => {})
                }
                SnackbarUtils.success(JSON.stringify(message))
                setUpdateRows(!updateRows) 
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const columns = React.useMemo(() => {
      const allColumns = {
        BUSINESS_UNIT: { 
            field: "BUSINESS_UNIT", 
            headerName: t("Adjustments.BUSINESS_UNIT"),
            flex: 0.06,
            minWidth: 50,
        },
        AC_AD_ID: { 
            field: "AC_AD_ID", 
            headerName: t("Adjustments.AcAd_ID"), 
            flex: 0.03,
            minWidth: 50,
            align: "center",
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Adjustment ID" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.AcAd_ID_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Adjustment ID" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.AcAd_ID")}
                </div>
              )
            },
        },
        PLAN_ACCOUNT: { 
            field: "PLAN_ACCOUNT", 
            headerName: t("Adjustments.PLAN_ACCOUNT"), 
            flex: 0.11,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width < 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Plan Account" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.PLAN_ACCOUNT_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Plan Account" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.PLAN_ACCOUNT")}
                </div>
              )
            },
        },
        AO_ID: { 
            field: "AO_ID", 
            headerName: t("Adjustments.AO_ID"),
            flex: 0.04,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Object ID" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.AO_ID_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Object ID" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.AO_ID")}
                </div>
              )
            },
        },
        ACTIVITY_ID: { 
          field: "ACTIVITY_ID", 
          headerName: t("Adjustments.ACTIVITY_ID"),
          flex: 0.09,
          minWidth: 50,
          renderCell: (params) => (
            <Link
              style={{ color: "#f69e80" }}
              to={`/my-activities/accrual_object/${params.row.AO_ID}`}
              className="dark-mode-link-color"
            >
              {params.value}
            </Link>
          ),
        },
        AO_DESCRIPTION: { 
            field: "AO_DESCRIPTION", 
            headerName: t("Adjustments.AO_DESCRIPTION"),
            flex: 0.08,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="AO Description" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.AO_DESCRIPTION_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="AO Description" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.AO_DESCRIPTION")}
                </div>
              )
            },
        },
        PP1_DATE: { 
            field: "PP1_DATE", 
            headerName: t("Adjustments.1PP_DATE"), 
            flex: 0.06,
            minWidth: 50,
            renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        },
        PP1_AMOUNT: { 
            field: "PP1_AMOUNT", 
            headerName: t("Adjustments.1PP_AMOUNT"),
            flex: 0.07,
            minWidth: 50,
            align: "right",
            renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
        },
        PP2_DATE: { field: "PP2_DATE", 
            headerName: t("Adjustments.2PP_DATE"),
            flex: 0.06,
            minWidth: 50,
            renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        },
        PP2_AMOUNT: { 
            field: "PP2_AMOUNT", 
            headerName: t("Adjustments.2PP_AMOUNT"),
            flex: 0.07,
            minWidth: 50,
            align: "right",
            renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
        },
        AC_AD_STATUS: { 
            field: "AC_AD_STATUS", 
            headerName: t("Adjustments.AcAd_STATUS"),
            flex: 0.06,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Adjustment Status" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.AcAd_STATUS_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Adjustment Status" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.AcAd_STATUS")}
                </div>
              )
            },
        },
        USER_ID: { 
            field: "USER_ID", 
            headerName: t("Adjustments.USER_ID"),
            flex: 0.08,
            minWidth: 50,
        },
        TIMESTAMP: { 
            field: "TIMESTAMP", 
            headerName: t("Adjustments.TIMESTAMP"),
            flex: 0.07,
            minWidth: 50,
            renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        },
        STATUS_MESSAGE: { 
            field: "STATUS_MESSAGE", 
            headerName: t("Adjustments.STATUS_MESSAGE"),
            flex: 0.06,
            minWidth: 50,
        },
        PP1_MESSAGE_ID: { 
            field: "PP1_MESSAGE_ID", 
            headerName: t("Adjustments.1PP_MESSAGE_ID"),
            flex: 0.06,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="1st Posting ID" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.1PP_MESSAGE_ID_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="1st Posting ID" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.1PP_MESSAGE_ID")}
                </div>
              )
            },
            renderCell: (params) => {
              const link = (<Link
                style={{ color: "#f69e80" }}
                to={`/accrual-balance/accrual_posting`}
                className="dark-mode-link-color"
                >
                  {params.value}
                </Link>)
              if (params.row.PP1_STATUS === "Error") {
                return(
                  <div className="MuiDataGrid-cellContent center">
                    {link}
                    <Tooltip title="Accrual Adjustment Error" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                      <IconButton>
                        <ReportIcon className='onhold' sx={{ fontSize: "1.2rem", color:'red'}} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              }
              return (
                <div>
                  {link}
                </div>
              );
            },
        },
        PP1_ACCRUAL_EVENT_ID: { 
            field: "PP1_ACCRUAL_EVENT_ID", 
            headerName: t("Adjustments.1PP_ACCRUAL_EVENT_ID"),
            flex: 0.06,
            minWidth: 50,
        },
        PP2_MESSAGE_ID: { 
            field: "PP2_MESSAGE_ID", 
            headerName: t("Adjustments.2PP_MESSAGE_ID"),
            flex: 0.06,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="2nd Posting ID" 
                  data-mui-internal-clone-element="true">
                      {t("Adjustments.2PP_MESSAGE_ID_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="2nd Posting ID" 
                  data-mui-internal-clone-element="true">
                    {t("Adjustments.2PP_MESSAGE_ID")}
                </div>
              )
            },
            renderCell: (params) => {
              const link = (<Link
                style={{ color: "#f69e80" }}
                to={`/accrual-balance/accrual_posting`}
                className="dark-mode-link-color"
                >
                  {params.value}
                </Link>)

              if (params.row.PP2_STATUS === "Error") {
                return(
                  <div className="MuiDataGrid-cellContent center">
                    {link}
                    <Tooltip title="Accrual Adjustment Error" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                      <IconButton>
                        <ReportIcon className='onhold' sx={{ fontSize: "1.2rem", color:'red'}} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              }
              return (
                <div>
                  {link}
                </div>
              );
            },
        },
        PP2_ACCRUAL_EVENT_ID: { 
            field: "PP2_ACCRUAL_EVENT_ID", 
            headerName: t("Adjustments.2PP_ACCRUAL_EVENT_ID"),
            flex: 0.06,
            minWidth: 50,
        },
        actions: {
            field: "actions",
            type: "actions",
            headerName: t("Adjustments.ACTIONS"),
            width: 65,
            minWidth: 50,
            headerAlign: 'left',
            hideable: false,
            getActions: (params) => {
              const simulationResults = (
                  <a
                      style={{ color: "#f69e80", textDecoration:'none' }}
                      onClick={() => handleOpenResultsModal(params.row.AC_AD_ID, 
                        params.row.PP1_ACCRUAL_EVENT_ID, 
                        params.row.PP2_ACCRUAL_EVENT_ID, 
                        params.row.PLAN_ACCOUNT, 
                        true,
                        params.row.AO_ID,
                        params.row.PP1_AMOUNT,
                        params.row.PP2_AMOUNT,
                        params.row.AC_AD_STATUS
                        )}
                      className="dark-mode-link-color"
                      to={'/'}
                  >
                      {(params.row.AC_AD_STATUS === "Calculated" || params.row.AC_AD_STATUS === "Completed") ? t("Adjustments.CALCULATION") : t("Adjustments.SIMULATION")}
                  </a>
              )
    
              const execute = (
                  <a
                      style={{ color: "#f69e80" }}
                      onClick={() => handleOpenDialog("Execute", params.row.PLAN_ACCOUNT, params.row.AC_AD_ID)}
                      className="dark-mode-link-color"
                  >
                      {t("Adjustments.EXECUTE")}
                  </a>
              )
    
              const cancel = (
                  <a
                    style={{ color: "#f69e80" }}
                    onClick={() => handleOpenDialog("Cancel", params.row.PLAN_ACCOUNT, params.row.AC_AD_ID)}
                    className="dark-mode-link-color"
                  >
                      {t("Adjustments.CANCEL")}
                  </a>
              )
    
              const pp1accrualPostings = (
                  <Link
                      style={{ color: "#f69e80", textDecoration:'none' }}
                      to={`/accrual-balance/accrual_posting/${params.row.PP1_MESSAGE_ID}`}
                      className="dark-mode-link-color"
                  >
                      {t("Adjustments.ACCRUAL_POSTINGS_PP1")}
                  </Link>
              )
    
              const pp2accrualPostings = (
                <Link
                    style={{ color: "#f69e80", textDecoration:'none' }}
                    to={`/accrual-balance/accrual_posting/${params.row.PP2_MESSAGE_ID}`}
                    className="dark-mode-link-color"
                >
                    {t("Adjustments.ACCRUAL_POSTINGS_PP2")}
                </Link>
              )
    
              const aoAdjustmentsLogs = (
                  <a
                      style={{ color: "#f69e80" }}
                      onClick={() => handleOpenResultsModal(params.row.AC_AD_ID, params.row.PP1_ACCRUAL_EVENT_ID, params.row.PP2_ACCRUAL_EVENT_ID, params.row.PLAN_ACCOUNT, false, params.row.AO_ID)}
                      className="dark-mode-link-color"
                  >
                      {t("Adjustments.ADJUSTMENTS_LOGS")}
                  </a>
              )
    
              const resendApproval = (
                  <Link
                      style={{ color: "#f69e80", textDecoration:'none' }}
                      to={'/'}
                      className="dark-mode-link-color"
                  >
                      {t("Adjustments.RESEND_APPROVAL")}
                  </Link>
              )
    
              const approvalHistory = (
                  <Link
                      style={{ color: "#f69e80", textDecoration:'none' }}
                      to={'/'}
                      className="dark-mode-link-color"
                  >
                      {t("Adjustments.APPROVAL_HISTORY")}
                  </Link>
              )
    
              return [
                <CustomLinkTable
                  id={params.row.id}
                  links={
                    [
                      ((params.row.AC_AD_STATUS === "Simulated" || params.row.AC_AD_STATUS === "Completed" || params.row.AC_AD_STATUS === "Calculated") 
                      && params.row.PP1_ACCRUAL_EVENT_ID && params.row.PP1_ACCRUAL_EVENT_ID) ? simulationResults : null,
                      params.row.AC_AD_STATUS === "Simulated" ? execute : null,
                      params.row.AC_AD_STATUS === "Simulated" ? cancel: null,
                      params.row.AC_AD_STATUS === "Completed" ? pp1accrualPostings : null,
                      params.row.AC_AD_STATUS === "Completed" ? pp2accrualPostings : null,
                      aoAdjustmentsLogs,
                      // resendApproval,
                      // approvalHistory
                    ].filter(t => t !== null)
                  }
                  adjustments={true}
                />
              ]
            },
        },
      }
  
      return orderedColumns.length?orderedColumns.reduce((acc, field) => {
        return [...acc, allColumns[field]];
      }, []):Object.values(allColumns);
    }, [orderedColumns]);

    const objBreadcrumb = [
      { label: t("Breadcrumb.home"), link: "/" },
      { label: t("Breadcrumb.accrualBalance"), link: "/accrual-balance" },
      { label: t("Breadcrumb.adjustments"), link: "/accrual-balance/accrual-adjustments" },
    ]

    const handleColumnOrderChange = React.useCallback((params) => {
      setOrderedColumns((prevOrderedColumns) => {
        const newOrderedColumns = [...prevOrderedColumns];
        const oldIndex = params.oldIndex;
        const targetIndex = params.targetIndex;
        const oldColumn = prevOrderedColumns[oldIndex];
        newOrderedColumns.splice(oldIndex, 1);
        newOrderedColumns.splice(targetIndex, 0, oldColumn);
        saveUserPrefsWithoutRefresh({
          ...prefsData,
          reports_column_order: {
            ...prefsData.reports_column_order,
            Adjustments: newOrderedColumns
          }
        })
        return newOrderedColumns;
      });
    }, []);

    const onChangeFontSize = (value)=>{
      setFontSize(value)
      saveUserPrefs({
        ...prefsData,
        reports_column_size:{
          ...prefsData.reports_column_size,
          Adjustments: value
        }
      })
    }
    

    return (
      <div className="tableContainer">
        {newAdjustmentOpen && <AccrualAdjustmentModal open={newAdjustmentOpen} handleClose={() => setNewAdjustmentOpen(false)} setUpdateRows={setUpdateRows}/>}
        <DialogAdjustments dialogType={dialogType} handleAction={handleAction} dialogOpen={dialogOpen} acAd={acAd} planAccount={planAccount} handleClose={() => setDialogOpen(false)}/>
        <AdjustmentResultsModal 
          open={openResultsModal} 
          handleClose={() => setOpenResultsModal(false)} 
          acAd={acAd} 
          eventId1={eventId1} 
          eventId2={eventId2} 
          planAccount={planAccount}
          simulation={isSimulation}
          aoId={aoId}
          budget1={budgets.PP1_AMOUNT}
          budget2={budgets.PP2_AMOUNT}
          status={status}
          setting={{
            exportSetting: {
              endpoint: backend_url.adjustment_simulation_excel_file + '?' + exportQuery + `&AC_AD_ID=${acAd}`,
              endpointCount: backend_url.accrual_adjustment_excel_count + '?' + exportQuery,
              fileName: "Accrual Adjustment Simulation Report"
            }
          }}
        />
        <DataGridPro
          apiRef={apiRef}
          sx={{ 
            '& .MuiDataGrid-row': {
              minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
              height: 'auto !important'
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            },
            '& .MuiDataGrid-main':{
              fontSize: checkSize(fontSize, 'font_size')
            }  
          }} 
          rows={rows}
          columns={columns}
          loading={loading}
          disableSelectionOnClick={true}
          sortingOrder={['desc', 'asc']}
          getRowHeight={() => 'auto'}
          headerHeight={checkSize(fontSize, 'header_height')}
          disableColumnFilter
          onColumnOrderChange={handleColumnOrderChange}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          onColumnVisibilityModelChange={saveColumnVisibility}
          onPreferencePanelClose={()=> updateUserPrefsOnClose()}
          initialState={{
            columns: {
              columnVisibilityModel:columnVisibility
            },
          }}
          localeText={{
            toolbarColumns: t("Buttons.Columns"),
            toolbarExport: t("Buttons.Export"),
            noRowsLabel: t("Common.noRows")

          }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: CustomLoadingOverlay,
            Panel: CustomPanel
          }}
          componentsProps={{
            toolbar: {
              bread: objBreadcrumb,
              title: t("Title.adjustments"),
              displayColumn: true,
              newAdjustment: true,
              openAdjustment: () => setNewAdjustmentOpen(true),
              exportSetting: {
                endpoint: backend_url.accrual_adjustment_excel_file + '?' + exportQuery,
                endpointCount: backend_url.accrual_adjustment_excel_count + '?' + exportQuery,
                fileName: "Accrual Adjustment Report"
              },
              isFontSize: true,
              fontSize: fontSize,
              onChangeFontSize: onChangeFontSize,
              filters:
                <CustomMultipleFilter
                  onFilter={changeFilterValues}
                  dataFilter={filter}
                  loading={loading}
                />
            },
          }}
        />
      </div>
    );
}
