
import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay, useGridApiRef } from '@mui/x-data-grid-pro'

import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar } from '../../components'
import CustomPanel from '../../mui-components/CustomPanel'
import Switch from '@mui/material/Switch';
import { useFetch, usePermissions } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey, checkSize } from "../../utils/utils"
import {
  useGridApiContext,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from '@mui/x-data-grid-pro';
import { ModalRequest, ModalSummary, TradeExpeditureFilters } from './components'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddchartIcon from '@mui/icons-material/Addchart';
import UpdateIcon from '@mui/icons-material/Update';
import StarIcon from '@mui/icons-material/Star';
import LockIcon from '@mui/icons-material/Lock';
import ReportIcon from '@mui/icons-material/Report';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Link } from 'react-router-dom'
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardIcon from '@mui/icons-material/CreditCard';


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


const isEqual = (arr, obj) => {
  return !!arr.find(item =>
    item.YEAR === obj.YEAR &&
    item.REPORT[0] === obj.REPORT[0] &&
    JSON.stringify(item.PLAN_ACCOUNT.map(it => it.PLAN_ACCOUNT_BP_NR).sort()) === JSON.stringify(obj.PLAN_ACCOUNT.map(it => it.PLAN_ACCOUNT_BP_NR).sort()) &&
    JSON.stringify(item.AO_STATUS.map(it => it.AO_STATUS).sort()) === JSON.stringify(obj.AO_STATUS.map(it => it.AO_STATUS).sort())
  )
}

const additionalLines = (showBalance) => {
  if (showBalance) {
    return <><div className='trade-ballance-row'>
    </div>
      <div className='trade-ballance-row'>
      </div></>
  }
  return null
}



function CustomGridTreeDataGroupingCell(props) {

  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();

  const handleClick = (e) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    e.stopPropagation();
  };

  const row = apiRef.current.getRow(id)

  if (rowNode.depth) {
    return (<></>)
  }

  const title = rowNode.childrenExpanded ? "Hide lines" : 'Show lines';

  return (<div className="MuiDataGrid-cellContent">
    <Tooltip title={title} placement='right' arrow enterDelay={200} enterNextDelay={200}>
      <button onClick={handleClick} className='arrow  bpx-trade-icons' style={{ paddingLeft: '5px', height: '35px' }}>
        {rowNode.childrenExpanded ? '▼' : '▶'}
      </button>
    </Tooltip>
    {additionalLines(row.showBalance)}
  </div>

  );
}



export default function TradeExpeditiureMonitor() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation(['translation', 'mars_dictionaries'])
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const requestedDataLS = localStorage.getItem('trade-is-request') ? JSON.parse(localStorage.getItem('trade-is-request')) : {};
  const lastPeriodDataLS = localStorage.getItem('trade-reports') ? JSON.parse(localStorage.getItem('trade-reports')) : [-1];
  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")
  const [fetcherRows, setFetcherRows] = useState([])

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(100)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({ YEAR: '', REPORT: [], AO_STATUS: [], PLAN_ACCOUNT: [], PLAN_ACCOUNT_BP_CHANNEL:[],PLAN_ACCOUNT_BP_SUB_CHANNEL:[] })
  const [summaryFilter, setSummaryFilters] = useState({ EXP_SUBTOTALS_BY: "ExternalCategory", EXP_SUMMARY_FOR: "Inv. Nature and Mechanic" })
  const [open, setOpen] = useState({ request: false, summary: false })
  const [textForModalRequest, setTextForModalRequest] = useState('')
  const [showPeriods, setShowPeriods] = useState(false)
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose } = useUserPrefs();
  const [lastPeriods, setLastPeriods] = useState(0)
  const periods = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'P13']
  const [summaryData, setSummaryData] = useState({})
  const [requestedData, setRequestedData] = useState({})

  const [columnVisibility, setColumnVisibility] = useState({});
  const [showAOLines, setShowAoLines] = useState(false)
  const [showAllBalance, setShowAllBalance] = useState(false)
  const [exportQuery, setExportQuery] = useState('')

  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.TradeExpeditiureMonitor || 'm');


  const showPeriodsTemplate = {
    field: 'showPeriods',
    headerName: '+',
    headerAlign: 'center',
    minWidth: 30,
    maxWidth: 30,
    sortable: false,
    hideable: false,
    align: 'center',
    headerClassName: 'bpx-trade-header-show-periods',
    cellClassName: 'bpx-trade-cell',
    disableColumnMenu: true,
    renderHeader: (params) => {
      return params.colDef.headerName === '+' ? <AddCircleOutlineIcon sx={{ fontSize: '1rem' }} /> : <RemoveCircleOutlineIcon sx={{ fontSize: '1rem' }} />
    },
    renderCell: (params) => {
      return (
        <div className="MuiDataGrid-cellContent" style={{ justifyContent: 'flex-end' }}>
          <div className='trade-main-name'>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
          {additionalLines(params.row.showBalance)}
        </div>)
    }
  };

  useEffect(() => {
    let prevColumnVisibility = {}
    prevColumnVisibility['showPeriods'] = false
    prevColumnVisibility['id'] = false
    periods.forEach(it => {
      prevColumnVisibility[it] = false
    })
  
  
      request.fetchData(
        `${backend_url.config_report_parameters}?business_unit=${value_bu}&REPORT_CODE=TEM&PARAMETER_NAME=Investment Nature Header`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false
      ).then(data=>{
        if (prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.TradeExpeditiureMonitor) {
          prefsData.reports_column_visibilty.TradeExpeditiureMonitor.forEach(it => prevColumnVisibility[it] = false);
        }
        if(data.length){
          prevColumnVisibility['EXT_INV_NATURE']=data[0].PARAMETER_VALUE.toLowerCase()==='true'
        }
   
        setColumnVisibility(prevColumnVisibility)
      })
    
  }, [])

  const createUrl = (data, dataSkip) => {
    let param = {
      limit,
      skip: dataSkip.skip,
      BUSINESS_UNIT: value_bu
    };
    let queryString = getParam(param).toString();

    data.PLAN_ACCOUNT.forEach(it => {
      queryString += `&PLAN_ACCOUNTS={"PLAN_ACCOUNT_BP_NR":"${it.PLAN_ACCOUNT_BP_NR}","PLAN_ACCOUNT_BP_CAT":"${it.PLAN_ACCOUNT_BP_CAT}"}`
    })

    data.AO_STATUS.forEach(it => {
      queryString += `&AO_STATUSES=${it.AO_STATUS}`
    })
    data.PLAN_ACCOUNT_BP_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_CHANNELS=${value}`
    })
    data.PLAN_ACCOUNT_BP_SUB_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_SUB_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_SUB_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_SUB_CHANNELS=${value}`
    })
    const paramsForExport = {
      YEAR: data.YEAR,
      VERSION: data.REPORT[0],
    }

    setExportQuery(getParam(paramsForExport).toString() + '&'+ queryString)

    return `/header/${data.YEAR}/${data.REPORT[0]}/${dataSkip.page}?` + queryString;

  }

  const createUrlLines = (data) => {
    let param = {
      limit: 1000000,
      // skip: 0,
      BUSINESS_UNIT: value_bu
    };
    let queryString = getParam(param).toString();
    return `/lines/${data.YEAR}/${data.REPORT[0]}/${data.AO_ID}/${data.VERSION}?` + queryString;
  }




  const fetchData = (isHier = false, data, lastPeriods = 0, dataSkip = { skip, page }, showBalance=showAllBalance) => {
    if (isHier) {
      return request.fetchData(
        backend_url.trade_expenditure_monitor + createUrl(data, dataSkip),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false
      ).then(res => {
        setCount(res.length)
        return res.map((it, ind) => {
          const accrualPosted = {};
          const payments = {};
          const balance = {};
          for (const property in it.ACCRUAL_POSTED) {
            if (property.includes('P')) {
              if (property.slice(-4) !== data.YEAR) {
                continue;
              }
              const name = property.substring(0, property.indexOf('_'))
              accrualPosted[name] = it.ACCRUAL_POSTED[property]
              payments[name] = it.PAYMENTS && it.PAYMENTS[property] || '0.00'
              balance[name] = it.BALANCE && it.BALANCE[property] || '0.00'
            } else {
              accrualPosted[property] = it.ACCRUAL_POSTED[property]
              payments[property] = it.PAYMENTS && it.PAYMENTS[property] || '0.00'
              balance[property] = it.BALANCE && it.BALANCE[property] || '0.00'
            }
          }
          return {
            ...it,
            ...accrualPosted,
            childrenFetched: false,
            id: `${it.AO_ID}-${it.VERSION}`,
            currency: it.EXT_SPEND_TYPE === '2' ? '%' : '',
            EXT_SPEND_TYPE: it.EXT_SPEND_TYPE ? `${it.EXT_SPEND_TYPE} - ${t("EXT_SPEND_TYPE", { ns: 'mars_dictionaries' })[it.EXT_SPEND_TYPE]}` : '',
            hierarchy: [`${it.AO_ID}-${it.VERSION}`],
            BALANCE: balance,
            PAYMENTS: payments,
            showBalance: showBalance
          }
        })
      })
    }


    return request.fetchData(
      backend_url.trade_expenditure_monitor + createUrlLines(data),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(res => {
      if (!res.length) {
        return []
      }
      const title = {
        header: true,
        id: `${data.AO_ID}-${data.VERSION}-title`,
        LS_BUDGET_OR_AMOUNT: t("TradeExpeditiureMonitor.LS_BUDGET"),
        EXT_SPEND_TYPE: t("TradeExpeditiureMonitor.AO_LINE_ID"),
        EXT_CLASS: t("TradeExpeditiureMonitor.TYPE"),
        ACTIVITY_ID: t("TradeExpeditiureMonitor.ATTRIBUTE"),
        NIV: t("TradeExpeditiureMonitor.NIV"),
        P1: t("TradeExpeditiureMonitor.P1"),
        P2: t("TradeExpeditiureMonitor.P2"),
        P3: t("TradeExpeditiureMonitor.P3"),
        P4: t("TradeExpeditiureMonitor.P4"),
        P5: t("TradeExpeditiureMonitor.P5"),
        P6: t("TradeExpeditiureMonitor.P6"),
        P7: t("TradeExpeditiureMonitor.P7"),
        P8: t("TradeExpeditiureMonitor.P8"),
        P9: t("TradeExpeditiureMonitor.P9"),
        P10: t("TradeExpeditiureMonitor.P10"),
        P11: t("TradeExpeditiureMonitor.P11"),
        P12: t("TradeExpeditiureMonitor.P12"),
        P13: t("TradeExpeditiureMonitor.P13"),
        YTD: t("TradeExpeditiureMonitor.YTD"),
        hierarchy: [`${data.AO_ID}-${data.VERSION}`, `${data.AO_ID}-${data.VERSION}-title`]
      }

      const newData = res.map((it, ind) => {
        const accrualPosted = {};
        for (const property in it.ACCRUAL_POSTED) {
          if (property.includes('P')) {
            if (property.slice(-4) !== data.YEAR) {
              continue;
            }
            const name = property.substring(0, property.indexOf('_'))
            const emptyPeriods = periods.slice(lastPeriods + 1)

            if (emptyPeriods.includes(name)) {
              accrualPosted[name] = ''
            } else {
              accrualPosted[name] = it.ACCRUAL_POSTED[property]
            }
          } else {
            accrualPosted[property] = it.ACCRUAL_POSTED[property]
          }
        }
        return {
          ...it,
          ...accrualPosted,
          id: `${it.AO_ID}-${data.VERSION}-${it.AO_LINE_ID}`,
          EXT_SPEND_TYPE: it.AO_LINE_ID,
          EXT_CLASS: it.TYPE,
          currency: data.currency,
          EXT_INV_NATURE: '',
          ACTIVITY_ID: it.ATTRIBUTE,
          hierarchy: [`${data.AO_ID}-${data.VERSION}`, `${data.AO_ID}-${data.VERSION}-${it.AO_LINE_ID}`],
        }
      })

      newData.unshift(title)
      return newData
    })
  }

  const getSummaryData = (filters = { ...filters, ...summaryFilter }) => {

    let queryString = `BUSINESS_UNIT=${value_bu}`
    filters.PLAN_ACCOUNT.forEach(it => {
      queryString += `&PLAN_ACCOUNTS={"PLAN_ACCOUNT_BP_NR":"${it.PLAN_ACCOUNT_BP_NR}","PLAN_ACCOUNT_BP_CAT":"${it.PLAN_ACCOUNT_BP_CAT}"}`
    })
    filters.AO_STATUS.forEach(it => {
      queryString += `&AO_STATUSES=${it.AO_STATUS}`
    })
    filters.PLAN_ACCOUNT_BP_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_CHANNELS=${value}`
    })
    filters.PLAN_ACCOUNT_BP_SUB_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_SUB_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_SUB_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_SUB_CHANNELS=${value}`
    })
    
    const paramsForExport = {
      YEAR: filters.YEAR,
      VERSION: filters.REPORT[0],
    }

    setExportQuery(getParam(paramsForExport).toString() + '&'+ queryString)

    queryString+= `&EXP_SUBTOTALS_BY=${filters.EXP_SUBTOTALS_BY}&EXP_SUMMARY_FOR=${filters.EXP_SUMMARY_FOR}`

    return request.fetchData(
      `${backend_url.trade_expenditure_monitor}/summary/${filters.YEAR}/${filters.REPORT[0]}?` + queryString,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(data => {
      setSummaryData(data)
      return data
    })

  }
  // const ids = apiRef.current.getAllRowIds()
  const saveColumnVisibility = (data) => {

    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty: {
        ...prefsData.reports_column_visibilty,
        TradeExpeditiureMonitor: Object.entries(data).filter(it => !it[1]).map(it => it[0])
      }
    })
    setColumnVisibility(data)
  }

  const getRowId = (row) => {
    if (typeof row?.id === 'string' && row?.id.startsWith('placeholder-children-')) {
      return row.id;
    }
    return row.id;
  };

  function updateRows(
    apiRef,
    rows,
    updateVisibility = true,
    isAdd = true
  ) {

    if (!apiRef.current) {
      return;
    }

    const rowsToAdd = [...rows];
    if (updateVisibility) {
      if (rows.length) {
        const hiddenColumn = {}
        let lastPeriod = lastPeriods

        periods.map((itemCheck, ind) => {
          if (rows.map(it => it[itemCheck]).filter(it => it != '0.00').length && ind > lastPeriod) {
            lastPeriod = ind
          }
        })


        periods.map((it, ind) => {
          if (ind < lastPeriod - 1) {
            hiddenColumn[it] = false
          } else if (ind > lastPeriod + 1) {
            hiddenColumn[it] = false
          } else {
            hiddenColumn[it] = true
          }
        })
        const emptyPeriods = periods.slice(lastPeriod + 1)

        rowsToAdd.map(it => {
          emptyPeriods.forEach(period => {
            it[period] = ""
            it.BALANCE[period] = it.BALANCE[period] == '0.00' ? '' : it.BALANCE[period]
            it.PAYMENTS[period] = it.PAYMENTS[period] == '0.00' ? '' : it.PAYMENTS[period]
          })
          return it;
        })

        if (lastPeriod >= 2) {
          hiddenColumn['showPeriods'] = true
          setColumns(pr => [].concat(pr.filter(it => it.field !== "showPeriods").slice(0, lastPeriod + 13), showPeriodsTemplate, pr.filter(it => it.field !== "showPeriods").slice(lastPeriod + 13)))
        }
        setLastPeriods(lastPeriod)
        setColumnVisibility(prev => ({ ...prev, ...hiddenColumn }))
      }
    }

    rows.forEach((row) => {
      if (row.descendantCount && row.descendantCount > 0) {
        rowsToAdd.push({
          id: `placeholder-children-${getRowId(row)}`,
          hierarchy: [...row.hierarchy, ''],
        });
      }
    });

    if (isAdd) {
      apiRef.current.updateRows(rowsToAdd);

    } else {
      apiRef.current.setRows(rowsToAdd);
    }

    setLoading(false);
  }


  useEffect(() => {
    const handleRowExpansionChange = async (
      node
    ) => {
      
      const row = apiRef.current.getRow(node.id)

      if (!node.childrenExpanded || !row || row.childrenFetched) {
        return;
      }

        const childrenRows = await fetchData(false, { ...row, ...filters }, lastPeriods);
        if(!apiRef.current.getRow(row.id)){
          return;
        }
        
        updateRows(apiRef, [
          ...childrenRows,
          { id: row.id, childrenFetched: true },
          { id: `placeholder-children-${node.id}`, _action: 'delete' },
        ], false);
    };

    return apiRef.current.subscribeEvent(
      'rowExpansionChange',
      handleRowExpansionChange,
    );


  }, [apiRef, filters, lastPeriods])

  useEffect(() => {
    if (apiRef.current.state && apiRef.current.state.rows) {  
      const ids = apiRef.current.getAllRowIds();
      ids.filter(it => (it.match(/-/g) || []).length === 1).map(id => {
        apiRef.current.setRowChildrenExpansion(id, showAOLines);
        apiRef.current.setCellFocus(id, '__tree_data_group__');
        if(showAOLines){
          setFetcherRows(pr=>[...pr, id])
        }
      })
    }
  }, [showAOLines])

  const [lines, setLines] = useState(0)

  useEffect(()=>{
    setLines(apiRef.current.getAllRowIds().filter(it => (it.match(/-/g) || []).length === 1).length)

  }, [apiRef.current.state])

  useEffect(()=>{
    if(!showAOLines){
      return
    }
    const ids = apiRef.current.getAllRowIds().filter(it => (it.match(/-/g) || []).length === 1);

    ids.map(id => {
      apiRef.current.setRowChildrenExpansion(id, showAOLines);
      apiRef.current.setCellFocus(id, '__tree_data_group__');

      setFetcherRows(pr=>[...pr, id])

      
    })
  }, [lines])



  useEffect(() => {
    if (filters.YEAR && filters.REPORT.length && page != 1) {
      updateData(true, filters)
    }
  }, [page])

  useEffect(() => {
    if (filters.YEAR && filters.REPORT.length) {
      if (skip !== 0) {
        updateData(true, filters)
      } else {
        setPage(pg => pg + 1)
      }
    }
  }, [skip])





  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize && params.virtualRowsCount) {

      if (count >= limit) {
        return setSkip(skip + limit)
      } else if (skip === 0 && count !== 0) {
        return setPage(pg => pg + 1)
      } else {
        return setSkip(0);
      }
    }
  }


  const renderPeriod = (params) => {
    const { row, value, field } = params;


    if (isNaN(value)) {
      return <div className="MuiDataGrid-cellContent">
        <div className="trade-header-name">{value}</div>
        <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      </div>
    }


    const stylePayments = row.showBalance&&row.PAYMENTS[field] && row.PAYMENTS[field] < 0? {color: 'red'}: {}
    const styleBalance = row.showBalance&&row.BALANCE[field] && row.BALANCE[field] < 0 ? {color: 'red'}: {}
    return (
      <div className="MuiDataGrid-cellContent right">
        <div className='trade-main-row'>
          {value && numberFormatter(prefsData.decimal_notation).format(value)}
          <div className='trade-header-separator'>
            <svg><path d="M11 19V5h2v14z"></path></svg>
          </div>
        </div>
        {row.showBalance && <><div className='trade-ballance-row' style={stylePayments}>
          {row.PAYMENTS[field] && numberFormatter(prefsData.decimal_notation).format(row.PAYMENTS[field])}
        </div>
          <div className='trade-ballance-row' style={styleBalance}>
            {row.BALANCE[field] && numberFormatter(prefsData.decimal_notation).format(row.BALANCE[field])}
          </div></>}
      </div>
    )
  }




  const [columns, setColumns] = useState([
    {
      field: 'id',
      headerName: 'id',
      width: 10,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (params.row.hierarchy.length != 1) {
          return '';
        }
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value.split('-')[0]}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>)
      },
      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        return hierarchy[hierarchy.length - 1];
      },
    },
    {
      field: 'AO_STATUS',
      headerName: t("TradeExpeditiureMonitor.STATUS"),
      minWidth: 60,
      maxWidth: 60,
      hideable: false,
      padding: 0,
      cellClassName: 'status',
      align: 'center',
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        const { value } = params
        if (value === "NEW") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="NEW" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <StarIcon className='new' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        if (value === "CLOSED") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="CLOSED" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <LockIcon className='closed' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        if (value === "ONHOLD") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="ON-HOLD" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <ReportIcon className='onhold' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        if (value === "ACTIVE") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="ACTIVE" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <PlayCircleOutlineIcon className='active' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        if (value === "ENDED") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="ENDED" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <SportsScoreIcon className='ended' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        if (value === "DELETED") {
          return (
            <div className="MuiDataGrid-cellContent center">
              <Tooltip title="DELETED" placement="right" arrow enterDelay={200} enterNextDelay={200}>
                <IconButton>
                  <DisabledByDefaultIcon className='new' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        return <div className="MuiDataGrid-cellContent center">{value}{additionalLines(params.row.showBalance)}</div>

      }
    },
    {
      field: '__tree_data_group__',
      hideable: false,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 25,
      maxWidth: 25,
      hideable: false,
      cellClassName: 'bpx-trade-cell',
      headerClassName: 'hideSeparator',
      renderCell: (params) => {
        if (params.row.hierarchy.length != 1) {
          return <p></p>
        }
        const title = !params.value ? "Show additional information" : "Hide additional information"
        return (
          <div className="MuiDataGrid-cellContent bpx-trade-icons bpx-trade-icon-more-information">
            <Tooltip title={title} placement="right" arrow enterDelay={200} enterNextDelay={200}>
              <div>
                <IconButton sx={{ padding: '0 0 0 5px', width: '15px', height: '35px' }}>
                  {params.value ? <ContentPasteOffIcon sx={{ transform: 'scale(0.7,0.8)', color: 'rgb(56 101 124)' }} /> : <ContentPasteIcon sx={{ transform: 'scale(0.7)', color: 'rgb(56 101 124)' }} />}
                </IconButton>
              </div>
            </Tooltip>
            {additionalLines(params.row.showBalance)}
          </div>
        )

      },
    },
    {
      field: 'show_balance',
      headerName: '',
      minWidth: 25,
      maxWidth: 25,
      hideable: false,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (params.row.hierarchy.length != 1) {
          return <p></p>
        }
        const title = !params.row.showBalance ? "Show Payments & Balance" : "Hide Payments & Balance"
        return (
          <div className="MuiDataGrid-cellContent bpx-trade-icons bpx-trade-icon-more-information">

            <Tooltip title={title} placement="right" arrow enterDelay={200} enterNextDelay={200}>
              <div>
                <IconButton
                  sx={{ padding: '0', width: '15px', height: '35px' }}
                  onClick={() => {
                    apiRef.current.updateRows([{ id: params.id, showBalance: !params.row.showBalance }])
                  }}
                >
                  {params.row.showBalance ? <CreditCardIcon sx={{ transform: 'scale(0.7,0.8)', color: 'rgb(56 101 124)' }} /> : <AddCardIcon sx={{ transform: 'scale(0.7)', color: 'rgb(56 101 124)' }} />}
                </IconButton>
              </div>
            </Tooltip>

            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'PLAN_ACCOUNT_BP_NAME',
      headerName: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_NAME"),
      minWidth: 100,
      width: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'EXT_CATEGORY',
      headerName: t("TradeExpeditiureMonitor.EXT_CATEGORY"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (params.value) {
          return (
            <div className="MuiDataGrid-cellContent">
              <div className='trade-main-row'>
                {`${params.value} - ${t("EXT_CATEGORY", { ns: 'mars_dictionaries' })[params.value]}`}
              </div>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>

            </div>
            {additionalLines(params.row.showBalance)}
          </div>)
      }

    },
    {
      field: 'EXT_INV_NATURE',
      headerName: t("TradeExpeditiureMonitor.INVESTMENT_NATURE"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value&&params.value.replace("EXT_INV_NATURE_FLAGS.", "")}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'PLAN_ACCOUNT_BP_CHANNEL',
      headerName: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_CHANNEL"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'PLAN_ACCOUNT_BP_SUB_CHANNEL',
      headerName: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_SUB_CHANNEL"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'DATE_FROM_EXFACTORY',
      headerName: t("TradeExpeditiureMonitor.VALID_FROM"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (!params.value) {
          return <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {params.value}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'DATE_TO_EXFACTORY',
      headerName: t("TradeExpeditiureMonitor.VALID_TO"),
      minWidth: 100,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (!params.value) {
          return <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>{params.value}</div>
            {additionalLines(params.row.showBalance)}
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>
              {dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'EXT_SPEND_TYPE',
      headerName: t("TradeExpeditiureMonitor.EXT_SPEND_TYPE"),
      minWidth: 100,
      flex: 0.20,
      hideable: false,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return <div className="MuiDataGrid-cellContent">
          <div className='trade-main-row'>{params.value}</div>
          <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          {additionalLines(params.row.showBalance)}
        </div>
      }
    },
    {
      field: 'EXT_CLASS',
      headerName: t("TradeExpeditiureMonitor.EXT_CLASS"),
      minWidth: 100,
      flex: 0.20,
      hideable: false,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            <div className='trade-main-row'>{params.value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
            {additionalLines(params.row.showBalance)}
          </div>)
      }
    },
    {
      field: 'ACTIVITY_ID',
      headerName: t("TradeExpeditiureMonitor.ACTIVITY_ID"),
      minWidth: 100,
      hideable: false,
      flex: 0.20,
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (checkActivityID && params.row.hierarchy.length === 1) {
          return (
            <div className="MuiDataGrid-cellContent">
              <div className='trade-main-row'>
                <Link style={{ color: '#f69e80' }} to={`/my-activities/accrual_object/${params.row.AO_ID}`} target="_blank" className="dark-mode-link-color">
                  {params.value}
                </Link>
              </div>
              {additionalLines(params.row.showBalance)}
            </div>
          )
        }
        return <div className="MuiDataGrid-cellContent">
          <div className="trade-header-name">{params.value}</div>
          <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
        </div>
      }
    },
    {
      field: 'LS_BUDGET_OR_AMOUNT',
      headerName: t("TradeExpeditiureMonitor.LS_BUDGET"),
      minWidth: 50,
      //flex: 0.05,
      width: 90,
      hideable: false,
      align: "right",
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {

        if (isNaN(params.value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{params.value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent right">
            <div className='trade-main-row'>
              {numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.currency}
              <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
            </div>
            {params.row.showBalance && <><div className='trade-ballance-row'>
              Payments
            </div>
              <div className='trade-ballance-row'>
                Balance
              </div></>}
          </div>
        )
      }
    },
    {
      field: 'NIV',
      headerName: t("TradeExpeditiureMonitor.NIV"),
      minWidth: 50,
      width: 90,
      //flex: 0.10,
      hideable: false,
      align: "right",
      cellClassName: 'bpx-trade-cell',
      renderCell: (params) => {
        if (isNaN(params.value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{params.value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent right">
            <div className='trade-main-row'>
              {numberFormatter(prefsData.decimal_notation).format(params.value)}
              <div className='trade-header-separator'>
                <svg><path d="M11 19V5h2v14z"></path></svg>
              </div>
            </div>
            {additionalLines(params.row.showBalance)}
          </div>
        )
      }
    },
    {
      field: 'P1',
      headerName: t("TradeExpeditiureMonitor.P1"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod,
    },
    {
      field: 'P2',
      headerName: t("TradeExpeditiureMonitor.P2"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod,
    },
    {
      field: 'P3',
      headerName: t("TradeExpeditiureMonitor.P3"),
      //flex: 0.05,
      width: 90,
      minWidth: 50,
      align: "right",
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P4',
      headerName: t("TradeExpeditiureMonitor.P4"),
      //flex: 0.05,
      width: 90,
      minWidth: 50,
      align: "right",
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P5',
      headerName: t("TradeExpeditiureMonitor.P5"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P6',
      headerName: t("TradeExpeditiureMonitor.P6"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P7',
      headerName: t("TradeExpeditiureMonitor.P7"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P8',
      headerName: t("TradeExpeditiureMonitor.P8"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P9',
      headerName: t("TradeExpeditiureMonitor.P9"),
      minWidth: 50,
      width: 90,
      //flex: 0.05,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P10',
      headerName: t("TradeExpeditiureMonitor.P10"),
      //flex: 0.05,
      minWidth: 50,
      width: 90,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P11',
      headerName: t("TradeExpeditiureMonitor.P11"),
      //flex: 0.05,
      minWidth: 50,
      width: 100,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P12',
      headerName: t("TradeExpeditiureMonitor.P12"),
      //flex: 0.05,
      minWidth: 50,
      width: 100,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'P13',
      headerName: t("TradeExpeditiureMonitor.P13"),
      // flex: 0.05,
      minWidth: 50,
      width: 100,
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
    {
      field: 'YTD',
      headerName: 'YTD',
      //flex: 0.05,
      minWidth: 50,
      width: 100,
      hideable: false,
      align: "right",
      cellClassName: 'bpx-trade-cell',
      renderCell: renderPeriod
    },
  ])

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Menu.activities"), link: '' },
    { label: t("Menu.tradeExpeditiureMonitor"), link: '/my-activities/trade_expenditure_monitor' },
  ]

  const groupingColDef = {
    headerName: t("TradeExpeditiureMonitor.TOOLS"),
    maxWidth: 20,
    minWidth: 20,
    hideable: false,
    headerClassName: 'hideSeparator',
    cellClassName: 'bpx-trade-cell',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };


  const getDetailPanelContent = ({ row }) => {
    const { ACTIVITY_ID, PLAN_ACCOUNT_BP_NR, AO_DESCRIPTION, AO_CURRENCY, AO_STATUS, DATE_FROM_EXFACTORY, DATE_TO_EXFACTORY } = row;

    const currency = AO_CURRENCY ? (<li> {t("TradeExpeditiureMonitor.DETAIL.Currency")}{AO_CURRENCY}</li>) : ''
    const classNameStatus = AO_STATUS ? AO_STATUS.toLowerCase() : ''
    const oneDay = 24 * 60 * 60 * 1000;

    let statusText = ''
    if (AO_STATUS === "ACTIVE") {
      const days = Math.round(Math.abs((new Date() - new Date(DATE_FROM_EXFACTORY)) / oneDay));
      statusText = t("TradeExpeditiureMonitor.DETAIL.Status_3") + days + (days > 1 ? t("TradeExpeditiureMonitor.DETAIL.Status_4") : t("TradeExpeditiureMonitor.DETAIL.Status_5"))
    } else if (AO_STATUS === "ENDED") {
      const days = Math.round(Math.abs((new Date() - new Date(DATE_TO_EXFACTORY)) / oneDay));
      statusText = t("TradeExpeditiureMonitor.DETAIL.Status_3") + days + (days > 1 ? t("TradeExpeditiureMonitor.DETAIL.Status_4") : t("TradeExpeditiureMonitor.DETAIL.Status_5"))
    } else if (AO_STATUS === "NEW") {
      const days = Math.round(((new Date(DATE_FROM_EXFACTORY)) - new Date()) / oneDay);
      if (days > 0) {
        statusText = t("TradeExpeditiureMonitor.DETAIL.Status_2") + days + (days > 1 ? t("TradeExpeditiureMonitor.DETAIL.Status_4") : t("TradeExpeditiureMonitor.DETAIL.Status_5"))
      }
    }


    return (<div className='trade-bpx-detail'>
      <ul style={{ margin: 0, padding: 0 }}>
        <li>{t("TradeExpeditiureMonitor.DETAIL.Activity_1")}<b>{ACTIVITY_ID} </b>{t("TradeExpeditiureMonitor.DETAIL.Activity_2")}<b>{PLAN_ACCOUNT_BP_NR}</b></li>
        <li>{t("TradeExpeditiureMonitor.DETAIL.Description")}{AO_DESCRIPTION}</li>
        {currency}
        <li>{t("TradeExpeditiureMonitor.DETAIL.Status_1")}<b className={classNameStatus}>{AO_STATUS === "ONHOLD" ? "ON-HOLD" : AO_STATUS}</b>
          {statusText}
        </li>
      </ul>
    </div>)

  }


  const updateData = async (isAdd, filters, dataSkip, showBalance = showAllBalance) => {

    const column = await fetchData(true, filters, 0, dataSkip, showBalance)
    updateRows(apiRef, column, true, isAdd);
  }

  const onSummaryFilters = (name, value) => {
    setSummaryFilters(pr => ({ ...pr, [name]: value }))
    getSummaryData({ ...filters, ...summaryFilter, [name]: value })
  }

  const onFilters = async (filters) => {
    const cachedfilters = localStorage.getItem("filters")
    let filterObj = {TradeExpeditiureMonitor: filters}
    if (cachedfilters && Object.keys(cachedfilters).length) { 
      filterObj = JSON.parse(cachedfilters)
      filterObj["TradeExpeditiureMonitor"] = filters
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))
    setShowAllBalance(false)
    setShowAoLines(false)
    setLoading(true)
    setFilters(filters)
    setPage(1)
    setSkip(0)
    setShowPeriods(false)
    setLastPeriods(0)
    setFetcherRows([])

    if (filters.YEAR && filters.REPORT.length) {
      if (requestedData[filters.YEAR]) {
        setRequestedData(pr => ({ ...pr, [filters.YEAR]: false }))
      }
      getSummaryData({ ...filters, ...summaryFilter })
      await updateData(false, filters, { skip: 0, page: 1 }, false)
    } else {
      updateRows(apiRef, [], true, false);
    }
  }


  const onRequestReport = () => {
    setTextForModalRequest(t("TradeExpeditiureMonitor.ModalRequestText_1") + filters.YEAR + t("TradeExpeditiureMonitor.ModalRequestText_3"))
    setOpen(pr => ({ ...pr, request: true }))
    request.fetchData(
      `${backend_url.trade_expenditure_monitor}/${filters.YEAR}?BUSINESS_UNIT=${value_bu}`,
      'POST',
      null,
      false,
      tokenBpxRequest,
      true
    ).then(() => {
      const obj = localStorage.getItem('trade-is-request') ? JSON.parse(localStorage.getItem('trade-is-request')) : {}
      localStorage.setItem('trade-is-request', JSON.stringify({
        ...obj,
        request: {
          ...obj.request,
          [filters.YEAR]: {
            isRequest: true, lastPeriod: lastPeriodDataLS
          }
        }
      }))


    })
  }

  const onSummary = () => {
    let queryString = `?BUSINESS_UNIT=${value_bu}`
    filters.PLAN_ACCOUNT.forEach(it => {
      queryString += `&PLAN_ACCOUNTS={"PLAN_ACCOUNT_BP_NR":"${it.PLAN_ACCOUNT_BP_NR}","PLAN_ACCOUNT_BP_CAT":"${it.PLAN_ACCOUNT_BP_CAT}"}`
    })
    filters.AO_STATUS.forEach(it => {
      queryString += `&AO_STATUSES=${it.AO_STATUS}`
    })
    filters.PLAN_ACCOUNT_BP_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_CHANNELS=${value}`
    })
    filters.PLAN_ACCOUNT_BP_SUB_CHANNEL.forEach(it => {
      const value = it.PLAN_ACCOUNT_BP_SUB_CHANNEL === '[Empty]'? 'null': it.PLAN_ACCOUNT_BP_SUB_CHANNEL
      queryString += `&PLAN_ACCOUNT_BP_SUB_CHANNELS=${value}`
    })
    request.fetchData(
      `${backend_url.trade_expenditure_monitor}/summary/${filters.YEAR}/${filters.REPORT[0]}` + queryString,
      'POST',
      null,
      false,
      tokenBpxRequest,
      true
    ).then(() => {

      const obj = localStorage.getItem('trade-is-request') ? JSON.parse(localStorage.getItem('trade-is-request')) : {};

      const summary = obj.summary ? obj.summary : []
      summary.push(filters)

      localStorage.setItem('trade-is-request', JSON.stringify({ ...obj, summary }))
    })
  }

  const onColumnHeaderClick = (ev, event, detail) => {
    if (ev.field === 'showPeriods') {
      if (!showPeriods) {
        const hiddenColumn = columnVisibility

        if (lastPeriods < periods.length) {
          periods.slice(0, lastPeriods).forEach(it => {
            hiddenColumn[it] = true
          })
        }

        setColumnVisibility(hiddenColumn)
      } else {
        const hiddenColumn = columnVisibility

        if (lastPeriods < periods.length) {
          periods.slice(0, lastPeriods - 1).forEach(it => {
            hiddenColumn[it] = false
          })
        }

        setColumnVisibility(hiddenColumn)
      }
      setColumns(pr => pr.map(it => {
        if (it.field === 'showPeriods') {
          it.headerName = showPeriods ? '+' : '-'
        }
        return it
      }))
      setShowPeriods(pr => !pr)
    }
  }


  const handlePrepareSummaryClick = () => {
    setTextForModalRequest(t("TradeExpeditiureMonitor.ModalRequestText_2") + filters.REPORT[0] + t("TradeExpeditiureMonitor.ModalRequestText_4"))
    setOpen(pr => ({ ...pr, request: true }))
    onSummary()
  }

  const handleSummaryClick = () => {
    // setSummaryFilters(pr=>({...pr, [name]: value}))
    getSummaryData({ ...filters, ...summaryFilter }).then(data => {
      if (!data.length && (!requestedDataLS.summary || !isEqual(requestedDataLS.summary, filters))) {
        onSummary()
        setTextForModalRequest(t("TradeExpeditiureMonitor.ModalRequestText_2") + filters.REPORT[0] + t("TradeExpeditiureMonitor.ModalRequestText_4"))
        setOpen(pr => ({ ...pr, request: true }))
      } else if (!data.length) {
        setTextForModalRequest(t("TradeExpeditiureMonitor.ModalRequestText_2") + filters.REPORT[0] + t("TradeExpeditiureMonitor.ModalRequestText_4"))
        setOpen(pr => ({ ...pr, request: true }))
      }
      else {
        setOpen(pr => ({ ...pr, summary: true }))
      }

    })
  }


  const onShowAOLines = () => {
    setShowAoLines(pr => !pr)
  }

  const onShowBalance = () => {
    const ids = apiRef.current.getAllRowIds().filter(item => item.split('-').length === 2);;
    const showBalance = showAllBalance;
    apiRef.current.updateRows(ids.map(it => ({ id: it, showBalance: !showBalance })))
    setShowAllBalance(!showBalance)
  }

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        TradeExpeditiureMonitor: value
      }
    })
  }

  return (
    <div className="tableContainer bpx-trade">
      <DataGridPro
        disableColumnPinning
        treeData
        onColumnHeaderClick={onColumnHeaderClick}
        GridLinesVisibility="None"
        isableChildrenFiltering={true}
        disableChildrenSorting
        apiRef={apiRef}
        rows={rows}
        sx={{
          '.MuiDataGrid-main': {
            fontSize: checkSize(fontSize, 'font_size')
          },
          '& .hideSeparator > .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.hideSeparator .MuiDataGrid-columnHeaderTitleContainerContent': {
            overflow: 'visible'
          },
          '.hideSeparator .MuiDataGrid-columnHeaderTitleContainer': {
            overflow: 'visible'
          },
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
        }}
        columns={columns}
        loading={loading}

        // getRowHeight={(props) => {
        //   if (props.model.hierarchy.length != 1) {
        //     return 30
        //   }

        //   return 'auto';
        // }}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getTreeDataPath={(row) => row.hierarchy}
        groupingColDef={groupingColDef}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={({ row }) => 32}
        rowThreshold={0}
        getRowId={getRowId}
        disableColumnReorder
        getCellClassName={(params) => {


          const fields = ['showPeriods', 'ACTIVITY_ID', 'EXT_CLASS', 'EXT_SPEND_TYPE', 'NIV', 'LS_BUDGET_OR_AMOUNT', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'P13', 'YTD']
          if (params.row.header) {
            if (fields.includes(params.field)) {
              if (params.field === 'EXT_SPEND_TYPE') {
                return 'bpx-trade-header bpx-trade-left bpx-trade-top';
              }
              return 'bpx-trade-header bpx-trade-top';
            }
            return 'bpx-trade-top'
          } else if (params.row.hierarchy.length != 1 && params.field === 'EXT_SPEND_TYPE') {
            return 'bpx-trade-left'
          }
          return ''
        }}
        getRowClassName={(params) => {
          if (params.row.hierarchy.length == 1) {
            return 'bpx-trade-hierarchy'
          }
        }}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={() => updateUserPrefsOnClose()}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          }
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}

        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.TradeExpeditiureMonitor"),
            displayColumnForTrade: true,
            tradeData: [
              {
                headerName: "AO Lines",
                icon: <Switch
                  checked={showAOLines}
                  size="small"
                  sx={{ transform: 'scale(0.8) translate(-10px, -5px)' }}
                />,
                style: { width: '30px' },
                onClick: onShowAOLines,
                disabled: false
              },
              {
                headerName: "Payments & Balance",
                icon: <Switch
                  checked={showAllBalance}
                  size="small"
                  sx={{ transform: 'scale(0.8) translate(-10px, -5px)' }}
                />,
                disabled: false,
                style: { width: '30px' },
                onClick: onShowBalance
              },
              {
                headerName: t("Buttons.Refresh"),
                onClick: onRequestReport,
                icon: <UpdateIcon sx={{ fontSize: "1rem", color: 'rgb(246,109,56)' }} />,
                style: {},
                disabled: !filters.YEAR || (requestedDataLS.request && requestedDataLS.request[filters.YEAR] && requestedDataLS.request[filters.YEAR].isRequest && requestedDataLS.request[filters.YEAR].lastPeriod[0] === lastPeriodDataLS[0]),
              },
              {
                headerName: t("Buttons.Prepare_Summary"),
                onClick: handlePrepareSummaryClick,
                icon: <AddchartIcon sx={{ fontSize: "1rem", color: 'rgb(246,109,56)' }} />,
                style: {},
                disabled: !filters.YEAR || !filters.REPORT.length || summaryData.length || (requestedDataLS.summary && isEqual(requestedDataLS.summary, filters)),
              },
              {
                headerName: t("Buttons.Summary"),
                onClick: handleSummaryClick,
                style: {},
                icon: <InsertChartIcon sx={{ fontSize: "1rem", color: 'rgb(70,139,216)' }} />,
                disabled: false//!summaryData.length,
              },
            ],

            filters:
              <TradeExpeditureFilters
                onFilter={onFilters}
                filtersData={filters}
              />,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            exportSetting: {
                endpoint: backend_url.trade_expenditure_monitor_excel + '?' + exportQuery,
                fileName: "Trade Expediture Monitor",
                isTrade: true,
                toolbarExport: t("Buttons.Export"),
                isSummary: !!summaryData.length,
                summaryFilter: summaryFilter,
                disabled: loading,
              },
          }

        }}
      />
      <ModalRequest open={open.request} onClose={() => setOpen(pr => ({ ...pr, request: false }))} filters={filters} text={textForModalRequest} />
      <ModalSummary open={open.summary} data={summaryData} filters={{ ...summaryFilter, ...filters }} onFilterSummary={onSummaryFilters} onClose={() => setOpen(pr => ({ ...pr, summary: false }))} />

    </div>
  )
} 