import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay,  GridActionsCellItem } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { useFetch } from '../../../../hooks'
import { backend_url } from '../../../../settings'
import { getParam, checkSize, isAccessForSuperUser } from '../../../../utils/utils'
import CustomMultipleFilter from '../../../../mui-components/CustomMultipleFilter'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import ModalAssignNewRole from '../Modal/ModalAssignNewRole';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { ModalDelete } from '../../../../components'
import moment from 'moment'
import { useUserPrefs } from "../../../UserPreferences/ProvideUserPrefs";
import { useAuth } from '../../../../pages'
import { useNavigate } from 'react-router-dom'
import ModalAddNewUser from '../Modal/ModalAddNewUser';

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}


export default function  CRUDTable (props) {

  const { 
    title, url, action, initialFilter, initialColumns, breadcrumb, Toolbar, Component,userId,endpointForPut
  } = props

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')
  const currentUser = JSON.parse(localStorage.getItem('current_user'))
  const request = useFetch()

  const [rows, setRows] = useState([]);
  // const [columns, setColumns] = useState(initialColumns)
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingFetch, setLoadingFetch] = useState(false)
  const [userData, setUserData] = useState({})
  const [userRoles, setUserRoles] = useState(null)
  const [userCurrentRoles, setUserCurrentRoles] = useState(null)
  const [filter, setFilter] = useState(initialFilter)
  const [isOpen, setIsOpen] = useState({isAddData: false, isDeleteData: false, isEditUser: false});  

  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null)
  const dimensions = ['BUSINESS_PARTNER', 'CUSTOMER_HIERARCHY'];
  const { prefsData, saveUserPrefs } = useUserPrefs()
  const current_user = localStorage.getItem('current_user')?JSON.parse(localStorage.getItem('current_user')):null;
  const is_all_option = current_user&&current_user.all_access||[];
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.UserDetail || 'm');

  let auth = useAuth()
  let navigate = useNavigate()

  useEffect(()=>{
    const queryParams = {
      business_unit: value_bu,
      limit: 100,
    }

    const queryString = getParam(queryParams).toString() 

    request.fetchData(
      `${backend_url.model_userlist}/${userId}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      data.isActive = data.user_in_validity_period ?
        "Active": "Inactive"
      setUserData(data)
    })
  },[])

  useEffect(() => {
    request.fetchData(
        `${backend_url.user_roles}/${currentUser.username}`, 
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
    )
    .then(response => setUserCurrentRoles(response.map(res => res)))
    request.fetchData(
      `${backend_url.user_roles}/${userId}`, 
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
  )
  .then(response => setUserRoles(response.map(res => res)))
}, [])



  useEffect(()=>{
    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page, 
      USER_ID: userId
    }

    const queryString = getParam(queryParams).toString() 
    request.fetchData(
      `${url}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setCount(data.length);
      setLoading(false)
      const newObj = [];  
      let ind = rows.length;
      data.map((item) => {
        const {ROLE_ID, USER_ID, DATE_VALID_FROM, DATE_VALID_TO, DIMENSION_ID, DIMENSION_VALUE, SEQUENCE} = item;
        const el = newObj.find( it => 
          it.ROLE_ID === ROLE_ID &&
          it.DATE_VALID_TO === DATE_VALID_TO &&
          it.SEQUENCE===SEQUENCE
        ); 
        const dimensionID = DIMENSION_ID;
        const dimensionValue = dimensionID === 'BUSINESS_UNIT'? DIMENSION_VALUE : [...DIMENSION_VALUE.split(',')];
        if(el){
          el[dimensionID]= dimensionValue;
        }else{
          newObj.push({
            id: ind,
            USER_ID,
            ROLE_ID,
            DATE_VALID_FROM,
            DATE_VALID_TO,
            [dimensionID]: dimensionValue,
            SEQUENCE,
          })
          ind++;
        }
        return item;
      })
  
      setRows((old) => {
        return [...old, ...newObj]
      })
    })
  },[page])

  const changeFilterValues = (state) => {
    setFilter(state)
    setPage(0)
  }

  const createBody = (data)=>{
    const obj = [];
    const sameArray = rows.filter(it=>it.ROLE_ID === data.ROLE_ID && it.DATE_VALID_TO === data.DATE_VALID_TO)

    const SEQUENCE = sameArray.length? 
        Math.max(...sameArray.map(it=>it.SEQUENCE))+1:
      1;
  
    const dataForPost = {
      ROLE_ID: data.ROLE_ID,
      USER_ID: userId,
      DATE_VALID_FROM: data.DATE_VALID_FROM,
      DATE_VALID_TO: data.DATE_VALID_TO,
      SEQUENCE,
    }

    obj.push({
      ...dataForPost,
      DIMENSION_ID:'BUSINESS_UNIT',
      DIMENSION_VALUE:data.BUSINESS_UNIT
    });

    if(data.BUSINESS_PARTNER.length){
      obj.push({
        ...dataForPost,
        DIMENSION_ID:'BUSINESS_PARTNER',
        DIMENSION_VALUE:data.BUSINESS_PARTNER.join(',')
      });
    }

    if(data.CUSTOMER_HIERARCHY.length){
      obj.push({
        ...dataForPost,
        DIMENSION_ID:'CUSTOMER_HIERARCHY',
        DIMENSION_VALUE:data.CUSTOMER_HIERARCHY.join(',')
      });
    }
    return obj;
  }

  const fetchPut = (newData, oldData, key, value) =>{

    const {ROLE_ID, USER_ID, DATE_VALID_FROM, DATE_VALID_TO, SEQUENCE} = newData;
    const body = {
      ROLE_ID,
      USER_ID,
      DATE_VALID_FROM,
      DATE_VALID_TO,
      DIMENSION_ID:key, 
      DIMENSION_VALUE:value,
      SEQUENCE,
    }
    const dataForUrl = {
      ROLE_ID: oldData.ROLE_ID,
      USER_ID,
      DATE_VALID_TO: oldData.DATE_VALID_TO,
      DIMENSION_ID:key,
      SEQUENCE: oldData.SEQUENCE
    }

    return request.fetchData(
      backend_url.model_userRoles + getUrlForPut(dataForUrl) + '?BUSINESS_UNIT=' + value_bu,
      "PUT",
      JSON.stringify(body),
      false,
      tokenBpxRequest,
      false,
      true,
      false,
      true
      )
  }

  const fetchPost = (newData, key, value) =>{
    const {ROLE_ID, USER_ID, DATE_VALID_FROM, DATE_VALID_TO, SEQUENCE} = newData;
    const body = [{
      ROLE_ID,
      USER_ID,
      DATE_VALID_FROM,
      DATE_VALID_TO,
      DIMENSION_ID:key,
      DIMENSION_VALUE: value,
      SEQUENCE,
    }];

    return request.fetchData(
      `${backend_url.model_userRoles}?BUSINESS_UNIT=${value_bu}`,
      "POST",
      JSON.stringify(body),
      false,
      tokenBpxRequest,
      false,
      true,
      false,
      true
      )
  }


  const fetchDelete = (newData, key)=>{
    const {ROLE_ID, USER_ID, DATE_VALID_TO, SEQUENCE} = newData;

    const obj = {
      ROLE_ID,
      USER_ID,
      DATE_VALID_TO,
      DIMENSION_ID:key,
      SEQUENCE,
    };
    
    return request.fetchData(
      backend_url.model_userRoles +getUrlForPut(obj),
      "DELETE",
      null,
      false,
      tokenBpxRequest,
      false,
      true,
      false,
      true
      )
  }
 
  const createFetchArray = (oldData, newData, keys) => {
    const obj = []

    keys.map(key=>{

      if(oldData[key].length){
        if(newData[key].length){
          obj.push(fetchPut(newData, oldData, key, newData[key].join(',')))
        }else if (key=='CUSTOMER_HIERARCHY'){
          obj.push(fetchDelete(newData, key))
        }

      }else{
        if(newData[key].length){
          obj.push(fetchPost(newData, key,  newData[key].join(',')))
        }
      }
      return key;
    })
    return obj
  }
  

  const createBodyForGet = (newData)=>{
    const obj = [];
    if(editData.BUSINESS_UNIT){
      obj.push(fetchPut(newData, editData, 'BUSINESS_UNIT', newData.BUSINESS_UNIT))
    }else {
      obj.push(fetchPost(newData, 'BUSINESS_UNIT', newData.BUSINESS_UNIT))
    }
    obj.push(...createFetchArray(editData, newData, dimensions))
  
    return obj;
  }

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const handleEditClick = (id) => () => {
    const row = rows.find(it=>it.id===id)
    if(row){
      const {BUSINESS_PARTNER,BUSINESS_UNIT,CUSTOMER_HIERARCHY} = row;
      const obj = { 
        ...row,
        BUSINESS_UNIT: BUSINESS_UNIT ? BUSINESS_UNIT : '',
        BUSINESS_PARTNER: BUSINESS_PARTNER && BUSINESS_PARTNER.length ?
          BUSINESS_PARTNER.filter(it=> it!=='none' && it!=='') :
          [],
        CUSTOMER_HIERARCHY: CUSTOMER_HIERARCHY && CUSTOMER_HIERARCHY.length ? 
          CUSTOMER_HIERARCHY.filter(it=> it!=='none' && it!=='') :
          [],
      }
      if(obj.BUSINESS_UNIT.includes('all')&&!is_all_option.includes('BUSINESS_UNIT')){
        obj.BUSINESS_UNIT=[]
      }
      if(obj.BUSINESS_PARTNER.includes('all')&&!is_all_option.includes('BUSINESS_PARTNER')){
        obj.BUSINESS_PARTNER=[]
      }
      setEditData(obj)
    } 
    setIsOpen({isAddData:true, isDeleteData: false, isEditUser: false})
  }

  const createBodyForDelete = (data)=>{
    
    const { BUSINESS_PARTNER, CUSTOMER_HIERARCHY, BUSINESS_UNIT} = data;
    const obj = [];

    if(BUSINESS_UNIT){
       obj.push(fetchDelete(data, 'BUSINESS_UNIT'));
    }

    if(BUSINESS_PARTNER&&BUSINESS_PARTNER.length){
      obj.push(fetchDelete(data, 'BUSINESS_PARTNER'))
    }
    if(CUSTOMER_HIERARCHY&&CUSTOMER_HIERARCHY.length){
      obj.push(fetchDelete(data, 'CUSTOMER_HIERARCHY'))
    }
    return obj;
  }
  
  const onDeleteClick =  (id) => () => {
    const row = rows.find((row) => row.id === id); 
    if(row){
      setIsOpen({isAddData: false, isDeleteData: true, isEditUser: false});
      setDeleteData(row);
    }
    
  }

  const handleDeleteClick = () => {
    setLoadingFetch(true)
    if(deleteData){
      const promises = createBodyForDelete(deleteData);
      Promise.all(promises)
        .then(data=>{
          setRows(rows.filter(row=>row.id!==deleteData.id));
          setIsOpen({isAddData: false, isDeleteData: false, isEditUser: false});
          setDeleteData(null)
          SnackbarUtils.success(JSON.stringify('Successfully fetched'))
          setLoadingFetch(false)
          if(userId===prefsData.user_id){
            auth.signout(() => navigate("/login"))
          }
        }) 
        .catch(()=>setLoadingFetch(false))   
    }
  }

  const rowClassNameGetter = (params)=>{
    if (moment(params.row.DATE_VALID_TO).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ||
      moment(params.row.DATE_VALID_FROM).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD")) {
      return 'bpx-auth-section-past-row'; 
    }
    
    return '';
  }
  
  const getUrlForPut = (row)=>{
    let url = '';
   
    if(endpointForPut){
      url = '/' + endpointForPut.map(it=>row[it]).join('/')
    }
    return url;
  }

  const setNewData = (newData)=>{
    setLoadingFetch(true)
    if(newData.action==='POST'){
      const body = createBody(newData.data);
      request.fetchData(
        `${backend_url.model_userRoles}?BUSINESS_UNIT=${value_bu}`,
        "POST",
        JSON.stringify(body),
        false,
        tokenBpxRequest,
        false,
        false,
        false,
        true
        )
        .then(data => {
          const {BUSINESS_UNIT,BUSINESS_PARTNER,CUSTOMER_HIERARCHY} = newData.data
          const {SEQUENCE,ROLE_ID, DATE_VALID_FROM, DATE_VALID_TO,USER_ID} = body[0];
          const obj = {
            USER_ID,
            ROLE_ID,
            DATE_VALID_TO,
            DATE_VALID_FROM,
            BUSINESS_UNIT,
            BUSINESS_PARTNER,
            CUSTOMER_HIERARCHY,
            id: rows.length,
            SEQUENCE,
          }
          setRows(old=>[...old, obj])
          setIsOpen({isAddData:false, isDeleteData: false, isEditUser: false})
          SnackbarUtils.success('Successfully fetched')
          if(userId===prefsData.user_id){
            auth.signout(() => navigate("/login"))
          }
          setLoadingFetch(false)
        })
        .catch((e)=>{
          setLoadingFetch(false)
        })

      }else if(newData.action==="PUT"){
          const promises = createBodyForGet(newData.data, editData);
          Promise.all(promises)
            .then(()=>{
              if(dimensions.includes('BUSINESS_PARTNER')&&editData['BUSINESS_PARTNER'].length&&!newData.data["BUSINESS_PARTNER"].length){
                fetchDelete(newData.data, 'BUSINESS_PARTNER').then(()=>{
                  const {id} = newData.data
                  setRows(prev=>prev.map((row) => (row.id === id ? newData.data : row)))
                  setIsOpen({isAddData:false, isDeleteData: false, isEditUser: false})
                  SnackbarUtils.success(JSON.stringify('Successfully fetched'))
                  if(userId===prefsData.user_id){
                    auth.signout(() => navigate("/login"))
                  }
                  setLoadingFetch(false)
                }).catch((e)=>{
                  if(e=='401'){
                    SnackbarUtils.error("Unable to change this role")
                  }
                  setLoadingFetch(false)
                })
              }else{
                 const {id} = newData.data
                  setRows(prev=>prev.map((row) => (row.id === id ? newData.data : row)))
                  setIsOpen({isAddData:false, isDeleteData: false, isEditUser: false})
                  SnackbarUtils.success(JSON.stringify('Successfully fetched'))
                  if(userId===prefsData.user_id){
                    auth.signout(() => navigate("/login"))
                  }
                  setLoadingFetch(false)
              }
         
             
            }).catch((e)=>{
              if(e=='401'){
                SnackbarUtils.error("Unable to change this role")
              }
              setLoadingFetch(false)
            })
      }
  }

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      hide: action,
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={onDeleteClick(id)}
            color="inherit"
          />,
        ]
      },
    },
     ...initialColumns//.filter(it=>!it.joinTable)
  ]
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({...initialColumns.reduce((acc, item) => {
    acc[item.field] = true;
    return acc;
  }, {}), actions: false})

  useEffect(()=>{
    if(!isAccessForSuperUser(userCurrentRoles, userRoles)){
      setColumnVisibilityModel(prev=>({...prev,actions: true }))
    }

  },[userCurrentRoles,userRoles])

  const isFilter = initialFilter ? (
    <CustomMultipleFilter 
      onFilter={changeFilterValues} 
      dataFilter={filter} 
      preload={backend_url.my_balance_filter_values}
      loading={loading}
    />
  ) : false

  const handleClickAddNewRole = () => {
    setEditData(null);
    setIsOpen({isAddData:true, isDeleteData: false, isEditUser: false});
  }

  const handleClickChangeUserData = () => {
    setIsOpen(prev=>({...prev, isEditUser: true}));
  }


  const handleCloseAddModal = ()=>{
    setIsOpen({isAddData:false, isDeleteData: false, isEditUser: false})
  }

  const hanleCloseDeleteModal = ()=>{ 
    setDeleteData(null);
    setIsOpen({isAddData: false, isDeleteData: false, isEditUser: false})
  }

  const handleCloseEditUser = () => {
    setIsOpen({isAddData: false, isDeleteData: false, isEditUser: false})
  }


  const updateUserData = (data)=>{
    setLoadingFetch(true)
      request.fetchData(
        `${backend_url.model_userlist}/${data.USER_ID}?BUSINESS_UNIT=${value_bu}`,
        'PUT',
        JSON.stringify(data),
        null,
        tokenBpxRequest
      )
      .then(()=>{
        setUserData(data)
        setIsOpen(prev=>({...prev, isEditUser: false}))
        //setLoadingFetch(false)
      })
      .catch(()=>setLoadingFetch(false))
  }

  useEffect(()=>{setLoadingFetch(false)}, [isOpen])
  

  const warningRole = () => {
    if(!rows.length){
      return false;
    }
    return !rows.filter(it=>it.ROLE_ID==='AE Business Unit Basic'&&!(moment(it.DATE_VALID_TO).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ||
    moment(it.DATE_VALID_FROM).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD"))).length
  }

  const warningDelete = deleteData && deleteData.ROLE_ID === 'AE Business Unit Basic' && !(moment(deleteData.DATE_VALID_TO).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ||
  moment(deleteData.DATE_VALID_FROM).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD"))

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        UserDetail: value
      }
    })
  }

  return (
    <div className='tableContainer'>
      <DataGridPro
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }

        }}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        disableColumnFilter
        onRowsScrollEnd={handleOnRowsScrollEnd}
        hideFooter={true}
        experimentalFeatures={{ newEditingApi: true }}
        columnVisibilityModel={columnVisibilityModel}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        initialState={{
          sorting: {
            sortModel: [{ field: 'DATE_VALID_TO', sort: 'desc' },{ field: 'ROLE_ID', sort: 'asc' }],
          }
        }}
        
        components={{
          Toolbar: Toolbar,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            bread: breadcrumb,
            title: title,
            displayColumn: !isAccessForSuperUser(userCurrentRoles,userRoles),
            filters: isFilter,
            Component: Component,
            userData: userData,
            warning:  warningRole(),
            handleClickAddNewRole:handleClickAddNewRole,
            handleClickChangeUserData: handleClickChangeUserData,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            
          },
        }}
        getRowClassName={rowClassNameGetter}
      />
      <ModalAssignNewRole   
        open={isOpen.isAddData} 
        handleClose={handleCloseAddModal} 
        prevState={editData} 
        onHandleSubmit={setNewData}
        loadingFetch={loadingFetch}
        is_all_option={is_all_option}
      />
      <ModalDelete 
        open={isOpen.isDeleteData} 
        onClose={hanleCloseDeleteModal} 
        onSubmit={handleDeleteClick}
        warning={warningDelete}
        loading={loadingFetch}
      />
      <ModalAddNewUser 
        open={isOpen.isEditUser} 
        handleClose={handleCloseEditUser} 
        handleSubmit={updateUserData} 
        prevState={userData}
        loadingFetch={loadingFetch}
      />
    </div>
  )


}
