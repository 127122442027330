import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { useFetch, usePermissions } from '../../hooks'
import { FlexibleTabs, DialogChangeLogs, DialogRegenerate, DialogRecalculateAO } from '../../components'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import { numberFormatter, dateTimeFormatter, formatter } from '../../utils/utils'
import { ModalCloseAO } from '../../components';
import SnackbarUtils from '../../utils/SnackbarUtils'
import { getParam } from '../../utils/utils'
import { CustomLinkTable } from "../../components"
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { Tooltip } from '@mui/material'
import { minWidth } from '@mui/system'

export default function AOHeaderDetail() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const value_lang = localStorage.getItem('current_language')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const params = useParams()
  const request = useFetch()

  const [data, setData] = useState({isLocked: null})
  const [bp, setBP] = useState({})
  const { prefsData } = useUserPrefs();
  const [modal, setModal] = useState(false);
  const [statusClosingAO, setstatusClosingAO] = useState('');
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openRecalculate, setOpenRecalculate] = useState(false)
  const [isCalculated, setIsCalculated] = useState(false)
 
  const [openAction, setOpenAction] = useState(false)
  const [actionCal, setActionCal] = useState({})
  const [previousStatus, setPreviousStatus] = useState({
    status: ""
  })
  const [trigger, setTrigger] = useState(false)

  const { childObj } = usePermissions();

  const checkPermitionCloseAO = childObj.hasOwnProperty("AO_Close")

  const [cancelCalculation, setCancelCalculation] = useState(localStorage.getItem('cancel_calculation')? JSON.parse(localStorage.getItem('cancel_calculation')): []);

  const checkRefreshRunPlan = childObj.hasOwnProperty('RunPlan_Regeneration')



  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)
    request.fetchData(
      `${backend_url.accrual_object_header}/${params.id}?business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
      .then(data => {

        if (data.run_plan != null) {

          var extended_data = { "ae_attributes": [] }

          Object.keys(data.run_plan).forEach(key => {
            if (key === "ACD_START_DATE" || key === "ACD_END_DATE") {
              data.run_plan[key] = dateTimeFormatter(data.run_plan[key], prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            extended_data.ae_attributes.push({ ATTRIBUTE: t('AOHeaderDetail.PARAMETER.' + key), VALUE: data.run_plan[key] })
          });
          setData(prev=>({...prev, ...data, ...extended_data }))
          setstatusClosingAO(data.AO_STATUS)
        } else {
          setData(prev=>({...prev,  ...data }))
          setstatusClosingAO(data.AO_STATUS)
        }
        request.fetchData(
          `${backend_url.bp_list}/${data.PLAN_ACCOUNT_BP_CAT}/${data.PLAN_ACCOUNT_BP_NR}/detail?business_unit=${value_bu}`,
          'GET',
          null,
          false,
          tokenBpxRequest,
          false
        )
          .then(name => setBP(name))

    })
    request.fetchData(
      `${backend_url.acrual_object_bp}/${params.id}/locked?business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    ).then(data=>{
      setData(prev=>({...prev, isLocked: data }))
    })
  }, [prefsData])


  const title = `${data.ACTIVITY_ID} (${data.AO_DESCRIPTION})`

  const dataConvert = [
    {
      title: t('AOHeaderDetail.SECTION.BASIC_DATA'),
      collapsed: false,
      children: [
        {
          label: t("AOHeaderDetail.ACTIVITY_ID"),
          key: "ACTIVITY_ID",
          value: data.ACTIVITY_ID,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: true,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.AO_TYPE"),
          key: "AO_TYPE",
          value: data.AO_TYPE,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: true,
        },
        {
          label: t("AOHeaderDetail.AO_STATUS"),
          key: "AO_STATUS",
          value: data.AO_STATUS,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.AO_STATUS_REASON"),
          key: "AO_STATUS_REASON",
          value: data.AO_STATUS_REASON,
          tooltip: 'Message STATUS_REASON',
          editable: false,
          splitWord: false,
          spacer: false,
          cellClassName: "description-cell",
          renderCell: (value) => {
            if(!value||value==='Invalid date'){
              return <p>n/a</p>
            }
            if(value.length<50){
              return <p>{value}</p> 
            }
            return(
              <Tooltip title={value} >
                <p>
                  <span className="table-cell-trucate" style={{width: 'auto'}}> {value}</span>
                </p>                  
              </Tooltip>
            )
            }
        },
        {
          label: t("AOHeaderDetail.LOCKED"),
          key: "LOCKED",
          value: data.isLocked? 'Warning. AO is locked': 'n/a',
          tooltip: 'Locked',
          editable: false,
          splitWord: false,
          spacer: false,
          getStyle: () => {
            if(data.isLocked){
              return {color: 'red'}
            }
            return  {}
          }
        },
        {
          label: t("AOHeaderDetail.AO_BUDGET"),
          key: "AO_BUDGET",
          value: data.AO_BUDGET ? numberFormatter(prefsData.decimal_notation).format(data.AO_BUDGET) : "n/a",
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: true,
          separator: true,
        },
        {
          label: t("AOHeaderDetail.AO_CURRENCY"),
          key: "AO_CURRENCY",
          value: data.AO_CURRENCY,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
      ]
    },
    {
      title: t('AOHeaderDetail.SECTION.PARTNERS_AND_DATES'),
      collapsed: false,
      children: [
        {
          label: t("AOHeaderDetail.PLAN_ACCOUNT_BP_NR"),
          key: "PLAN_ACCOUNT_BP_NR",
          value: `${data.PLAN_ACCOUNT_BP_NR} ${bp.NAME1}`,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.RECIPIENT_BP_NR"),
          key: "RECIPIENT_BP_NR",
          value: `${data.RECIPIENT_BP_NR} ${bp.NAME1}`,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.RECIPIENT_BP_CAT"),
          key: "RECIPIENT_BP_CAT",
          value: data.RECIPIENT_BP_CAT,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.DATE_FROM_EXFACTORY"),
          key: t("AOHeaderDetail.DATE_FROM_EXFACTORY"),
          value: data.DATE_FROM_EXFACTORY && dateTimeFormatter(data.DATE_FROM_EXFACTORY, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: true,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.DATE_TO_EXFACTORY"),
          key: "DATE_TO_EXFACTORY",
          value: data.DATE_TO_EXFACTORY && dateTimeFormatter(data.DATE_TO_EXFACTORY, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.DATE_FROM_CONSUMER"),
          key: "DATE_FROM_CONSUMER",
          value: data.DATE_FROM_CONSUMER && dateTimeFormatter(data.DATE_FROM_CONSUMER, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.DATE_TO_CONSUMER"),
          key: "DATE_TO_CONSUMER",
          value: data.DATE_TO_CONSUMER && dateTimeFormatter(data.DATE_TO_CONSUMER, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.WEEK_FROM_CONSUMER"),
          key: "WEEK_FROM_CONSUMER",
          value: data.WEEK_FROM_CONSUMER && dateTimeFormatter(data.WEEK_FROM_CONSUMER, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.WEEK_TO_CONSUMER"),
          key: "WEEK_TO_CONSUMER",
          value: data.WEEK_TO_CONSUMER && dateTimeFormatter(data.WEEK_TO_CONSUMER, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
      ]
    },
    {
      title: t('AOHeaderDetail.SECTION.EXTERNAL_CLASSIFICATION'),
      collapsed: false,
      children: [
        {
          label: t("AOHeaderDetail.INCOMING_BUSINESS_SEG"),
          key: "INCOMING_BUSINESS_SEG",
          value: data.INCOMING_BUSINESS_SEG && data.INCOMING_BUSINESS_SEG + " - " + t('BUSINESS_SEGMENT.' + data.INCOMING_BUSINESS_SEG, { ns: 'mars_dictionaries' }),
          tooltip: 'Message INCOMING_BUSINESS_SEG',
          editable: false,
          splitWord: false,
          spacer: false,
          json: true,
        },
        {
          label: t("AOHeaderDetail.EXT_CATEGORY"),
          key: "EXT_CATEGORY",
          value: data.EXT_CATEGORY,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.EXT_CLASS"),
          key: "EXT_CLASS",
          value: data.EXT_CLASS,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.EXT_SUBCLASS"),
          key: "EXT_SUBCLASS",
          value: data.EXT_SUBCLASS,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.EXT_SPEND_TYPE"),
          key: "EXT_SPEND_TYPE",
          value: data.EXT_SPEND_TYPE,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        // {
        //   label: t("AOHeaderDetail.EXT_INV_NATURE"),
        //   key: "EXT_INV_NATURE",
        //   value: null,
        //   tooltip: 'Message BP_NR',
        //   editable: false,
        //   splitWord: false,
        //   spacer: false,
        // },
      ]
    },
    {
      title: t('AOHeaderDetail.SECTION.ADMINISTRATION'),
      collapsed: false,
      children: [
        {
          label: t("AOHeaderDetail.AO_ID"),
          key: "AO_ID",
          value: data.AO_ID,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.VERSION"),
          key: "VERSION",
          value: String(data.VERSION),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t("AOHeaderDetail.AO_CREATED_ON"),
          key: "AO_CREATED_ON",
          value: data.AO_CREATED_ON && dateTimeFormatter(data.AO_CREATED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.AO_LAST_CHANGED_ON"),
          key: "AO_LAST_CHANGED_ON",
          value: data.AO_LAST_CHANGED_ON && dateTimeFormatter(data.AO_LAST_CHANGED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.LAST_CALCULATED"),
          key: "LAST_CALCULATED",
          value: data.LAST_CALCULATED && dateTimeFormatter(data.LAST_CALCULATED, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
          momento: true,
        },
        {
          label: t("AOHeaderDetail.EXT_CREATED_BY"),
          key: "EXT_CREATED_BY",
          value: data.EXT_CREATED_BY && data.EXT_CREATED_BY,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: true,
        },
        {
          label: t("AOHeaderDetail.EXT_LAST_CHANGED_BY"),
          key: "EXT_LAST_CHANGED_BY",
          value: data.EXT_LAST_CHANGED_BY && data.EXT_LAST_CHANGED_BY,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
      ]
    },
  ]


    const dataColumns = {
      ao_lines: [
        {
          field: 'AO_LINE_ID',
          headerName: t('AOHeaderDetail.AOL.AO_LINE_ID'),
          flex: 1,
          minWidth: 170
        },
        {
          field: 'EXT_LINE_ID',
          headerName: t('AOHeaderDetail.AOL.EXT_LINE_ID'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'AOL_TYPE',
          headerName: t('AOHeaderDetail.AOL.AOL_TYPE'),
          flex: 1,
          minWidth: 180
        },
        {
          field: 'AOL_KEY_ATTRIBUTES',
          headerName: t('AOHeaderDetail.AOL.AOL_KEY_ATTRIBUTES'),
          flex: 1,
          minWidth: 150
        },
        {
          field: 'AOL_AMOUNT',
          headerName: t('AOHeaderDetail.AOL.AMOUNT'),
          flex: 1,
          minWidth: 70
        },
        {
          field: 'AOL_CURRENCY',
          headerName: t('AOHeaderDetail.AOL.AOL_CURRENCY'),
          flex: 1,
          minWidth: 100
        },
        {
          field: 'PRICING_UNIT',
          headerName: t('AOHeaderDetail.AOL.PRICING_UNIT'),
          flex: 1,
          minWidth: 90
        },
        {
          field: 'PRICING_UNIT_UOM',
          headerName: t('AOHeaderDetail.AOL.PRICING_UNIT_UOM'),
          flex: 1,
          minWidth: 140
        },
        {
          field: 'AOL_BUDGET',
          headerName: t('AOHeaderDetail.AOL.AOL_BUDGET'),
          flex: 1,
          minWidth: 110,
          valueFormatter: ({ value }) => value ? numberFormatter(prefsData.decimal_notation).format(value) : '',
          align: "right"
        },
        {
          field: 'AOL_STATUS',
          headerName: t('AOHeaderDetail.AOL.AOL_STATUS'),
          flex: 1,
          minWidth: 110
        },
        {
          field: 'CALC_TYPE',
          headerName: t('AOHeaderDetail.AOL.CALC_TYPE'),
          flex: 1,
          minWidth: 110
        },
        {
          field: 'EXT_LINE_TYPE',
          headerName: t('AOHeaderDetail.AOL.EXT_LINE_TYPE'),
          flex: 1,
          minWidth: 110
        },
        {
          field: 'AOL_CREATED_ON',
          headerName: t('AOHeaderDetail.AOL.AOL_CREATED_ON'),
          flex: 1,
          minWidth: 150,
          valueFormatter: params => dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true)
        },
        {
          field: 'AOL_LAST_CHANGED_ON',
          headerName: t('AOHeaderDetail.AOL.AOL_LAST_CHANGED_ON'),
          flex: 1,
          minWidth: 170,
          valueFormatter: params => dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true)
        },
      ],
      ae_attributes: [
        {
          field: 'ATTRIBUTE',
          headerName: t('AOHeaderDetail.PARAMETER.ATTRIBUTE'),
          flex: 1,
          minWidth: 150
        },
        {
          field: 'VALUE',
          headerName: t('AOHeaderDetail.PARAMETER.VALUE'),
          flex: 1,
          minWidth: 230
        },
      ],
      business_partner: [
        {
          field: 'BP_ROLE',
          headerName: t('AOHeaderDetail.BUSINESS_PARTNER.BP_ROLE'),
          flex: 0,
          minWidth: 40
        },
        {
          field: 'BP_NR',
          headerName: t('AOHeaderDetail.BUSINESS_PARTNER.BP_NUMBER'),
          flex: 0,
          minWidth: 60
        },
        {
          field: 'NAME1',
          headerName: t('AOHeaderDetail.BUSINESS_PARTNER.BP_NAME_1'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'NAME2',
          headerName: t('AOHeaderDetail.BUSINESS_PARTNER.BP_NAME_2'),
          flex: 1, minWidth: 120
        },
      ],
      products: [
        {
          field: 'MATERIAL_NO',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_NO'),
          flex: 0,
          minWidth: 40
        },
        {
          field: 'MATERIAL_STATUS',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_STATUS'),
          flex: 0,
          minWidth: 60,
          renderCell: (params) => {
            if (!params.row.MATERIAL_STATUS || params.row.MATERIAL_STATUS === 'Not in run-plan') {
              return '';
            }
            return params.row.MATERIAL_STATUS
          }
        },
        {
          field: 'MATERIAL_CALC_SETTING',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_CALC_SETTING'),
          flex: 1,
          minWidth: 120,
          renderCell: (params) => {
            if (!params.row.CALC_SETTING || params.row.CALC_SETTING === 'Not in run-plan') {
              return '';
            }
            return params.row.CALC_SETTING
          }
        },
        {
          field: 'MATERIAL_RECALC_SETTING',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_RECALC_SETTING'),
          flex: 1,
          minWidth: 120,
          renderCell: (params) => {
            if (!params.row.RECALC_SETTING || params.row.RECALC_SETTING === 'Not in run-plan') {
              return '';
            }
            return params.row.RECALC_SETTING
          }
        },
        {
          field: 'MATERIAL_AOCLOSE_SETTING',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_AOCLOSE_SETTING'),
          flex: 1,
          minWidth: 120,
          renderCell: (params) => {
            if (!params.row.AOCLOSE_SETTING || params.row.AOCLOSE_SETTING === 'Not in run-plan') {
              return '';
            }
            return params.row.AOCLOSE_SETTING
          }
        },
        {
          field: 'MATERIAL_DESCRIPTION',
          headerName: t('AOHeaderDetail.MATERIAL.MATERIAL_DESCRIPTION'),
          flex: 1,
          minWidth: 120
        },
      ],
      run_plan: [
        {
          field: 'CALENDAR',
          headerName: t('AOHeaderDetail.PARAMETER.CALENDAR'),
          flex: 1,
          minWidth: 210
        },
        {
          field: 'SUBTOTAL_CODE',
          headerName: t('AOHeaderDetail.PARAMETER.SUBTOTAL_CODE'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'ACD_DAYS',
          headerName: t('AOHeaderDetail.PARAMETER.ACD_DAYS'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'DATE_RANGE_TYPE',
          headerName: t('AOHeaderDetail.PARAMETER.DATE_RANGE_TYPE'),
          flex: 1,
          minWidth: 100,
          valueFormatter: params => dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
        },
        {
          field: 'ACD_START_DATE',
          headerName: t('AOHeaderDetail.PARAMETER.ACD_START_DATE'),
          flex: 1,
          minWidth: 100,
          valueFormatter: params => dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
        },
        {
          field: 'ACD_END_DATE',
          headerName: t('AOHeaderDetail.PARAMETER.ACD_END_DATE'),
          flex: 1,
          minWidth: 100,
          valueFormatter: params => dateTimeFormatter(params.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
        },
        {
          field: 'AO_TYPE_MECHANIC',
          headerName: t('AOHeaderDetail.PARAMETER.AO_TYPE_MECHANIC'),
          flex: 1,
          minWidth: 130
        },
        {
          field: 'EXT_REFERENCE',
          headerName: t('AOHeaderDetail.PARAMETER.EXT_REFERENCE'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'SUBTOTAL_ALGCODE',
          headerName: t('AOHeaderDetail.PARAMETER.SUBTOTAL_ALGCODE'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'ACTUALS_INVALID',
          headerName: t('AOHeaderDetail.PARAMETER.ACTUALS_INVALID'),
          flex: 1,
          minWidth: 120
        },
        {
          field: 'BUDGET',
          headerName: t('AOHeaderDetail.PARAMETER.BUDGET'),
          flex: 1,
          minWidth: 120,
          renderCell: ({ value }) => {
            if (isNaN(value)) {
              return value
            }
            return numberFormatter(prefsData.decimal_notation).format(value)
          },
          align: "right"
        },
      ],
      cal_history: [
        {
          field: "actions",
          type: "actions",
          headerName: t("MyBalance.ACTIONS"),
          flex: 1,
          minWidth: 65,
          hideable: false, 
          getActions: (params) => {
            const status = params.row.AP_STATUS
            let isDisable = false
            const cancelBefore = cancelCalculation.filter(it=>it.id==params.row.RUN_ID)
            if(cancelBefore.length&&cancelBefore.at(-1).status==status){
                const date = new Date(cancelBefore.at(-1).date);
                const now = new Date();
                if((now - date) / (1000 * 60)<=5){
                  isDisable = true
                }             
            }
            return [
              <CustomLinkTable
                id={params.row.AO_ID}
                links={[
                   status === "Pending" || status === "Error" ? (
                    <button className="delete-style-button bpx-select-item cancel" disabled={isDisable} onClick={() => handleAction(params.row, status, "Cancelled")}>
                      Cancel
                    </button>
                  ) : null,
                ].filter(item => item !== null) } 
              />     
            ]

          },
        },
        { 
          field: "RUN_ID", 
          headerName: t("AOHeaderDetail.APH.RUN_ID"), 
          minWidth: 250,
        },
        {
          field: "AO_ID",
          headerName: t("AOHeaderDetail.APH.AO_ID")
        },
        {
          field: "VERSION",
          headerName: t("AOHeaderDetail.APH.VERSION")
        },
        {
          field: "DATE_POSTING",
          headerName: t("AOHeaderDetail.APH.DATE_POSTING"),
          flex: 1,
          renderCell: (value) => {
            if (value) {
              return dateTimeFormatter(value.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
          },
          minWidth: 50
        },
        {
          field: "DATE_CREATED_ON",
          headerName: t("AOHeaderDetail.APH.DATE_CREATED_ON"),
          flex: 1,
          renderCell: (value) => {
            if (value) {
              return dateTimeFormatter(value.value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
          },
          minWidth: 50
        },
        {
          field: "AP_TYPE",
          headerName: t("AOHeaderDetail.APH.AP_TYPE"),
          minWidth: 160
        },
        {
          field: "AP_STATUS",
          headerName: t("AOHeaderDetail.APH.AP_STATUS"),
          flex: 1,
          minWidth: 90,
          renderCell: ({ value }) => {
            switch(value) {
              case "Success":
                return (
                  <div style={{ color: "#00a651" }}>
                    {value}
                  </div>
                )
              case "Pending":
                return (
                  <div style={{ color: "#f66d38" }}>
                    {value}
                  </div>
                )
              case "SentToERP":
                return (
                  <div style={{ color: "#f66d38" }}>
                    {value}
                  </div>
                )
              case "Cancelled":
                return (
                  <div style={{ color: "#468bd8" }}>
                    {value}
                  </div>
                )
              case "Processing":
                return (
                  <div style={{ color: "#f66d38" }}>
                    {value}
                  </div>
                )
              case "Error":
                return (
                  <div style={{ color: "#de7300" }}>
                    {value}
                  </div>
                )
              default:
                return (
                  <div style={{ color: "#000" }}>
                    {value}
                  </div>
                )
            }
          } 
        },
      ]
    }

    const accordion = [
      {
        id: "2",
        dataKey: 'ao_lines',
        label: t("AOHeaderDetail.Tabs.AOLines"),
        collapse: 'ao_lines',
        fetching: false,
      },
      {
        id: "3",
        dataKey: 'ae_attributes',
        fetchKey: 'run_plan',
        label: t('AOHeaderDetail.Tabs.AeAttributes'),
        collapse: 'ae_attributes',
        fetching: true,
        url: `${backend_url.accrual_object_header}/${params.id}?business_unit=${value_bu}`
      },
      {
        id: "4",
        dataKey: 'business_partner',
        label: t('AOHeaderDetail.Tabs.BusinessPartner'),
        collapse: 'business_partner',
        fetching: true,
        url: `${backend_url.acrual_object_bp}/${params.id}/run_plan/customers?business_unit=${value_bu}`
      },
      {
        id: "5",
        dataKey: 'products',
        label: t('AOHeaderDetail.Tabs.Products'),
        collapse: 'products',
        fetching: true,
        url: `${backend_url.acrual_object_bp}/${params.id}/${value_lang}/run_plan/materials?business_unit=${value_bu}`
      },
      // { 
      //   id: "6", 
      //   dataKey: 'run_plan',
      //   label:  t('AOHeaderDetail.Tabs.RunPlan'), 
      //   collapse: 'run_plan',
      //   fetching: true,
      //   url: `${backend_url.accrual_object_header}/${params.id}?business_unit=${value_bu}&include_run_plan_data=true`
      // },
      {
        id: "7",
        dataKey: 'cal_history',
        label: t('AOHeaderDetail.Tabs.CalculationHistory'),
        collapse: 'cal_history',
        fetching: true,
        isPages: true,
        url: `${backend_url.accrual_posting_events_header}?business_unit=${value_bu}&ao_id=${params.id}`
      },
    ]

    const breadCrumb = [
      { label: t("Breadcrumb.home"), link: '/' },
      { label: t("Menu.activities"), link: '' },
      { label: t("Menu.myActivities"), link: '/my-activities/accrual_object' },
      { label: data.ACTIVITY_ID, link: '' },
    ]

  const dataLogs = {
    title: t("CHANGE_LOGS.TitleAO"),
    value: 'AO_ID',
    url: backend_url.acrual_object_bp,
    endpoint: [{ field: 'AO_ID', name: 'AO_ID' }],
    endpointName: '/run_plan/changelog', 
    checkToHide: [],
    prevHide: {},
    isTreeChar: true,
    skip: 3,
    limit: 3,
    isLevel: true,
    columns: [
      {
        field: 'Change_Time',
        headerName: t("CHANGE_LOGS.ChangeTime"),
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) => {
          if (value) {
            return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
          }
          return value
        },
      },
      { field: 'Area', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
      { field: 'Attribute', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
      {
        field: 'Business_Area/Primary_key',
        headerName: t("CHANGE_LOGS.PrimaryKey"),
        minWidth: 220,
      },
      {
        field: 'Action',
        headerName: t("CHANGE_LOGS.Action"),
        flex: 1,
        renderCell: ({ value }) => {
          switch (value) {
            case "updated":
              return (
                <div style={{ color: "#f66d38" }}>
                  {value}
                </div>
              )
            case "deleted":
              return (
                <div style={{ color: "#ff0000" }}>
                  {value}
                </div>
              )
            case "added":
              return (
                <div style={{ color: "#00a651" }}>
                  {value}
                </div>
              )
            default:
              return (
                <div style={{ color: "#000" }}>
                  {value}
                </div>
              )
          }
        }
      },
      { field: 'Old_value', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
      { field: 'New_value', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
      { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 }, 
      { field: 'Ext_User_ID', headerName: t("CHANGE_LOGS.ExtUserId"), flex: 1 },
    ]
  }

  useEffect(() => {
    if (data.AO_ID) {
      request.fetchData(
        `${backend_url.check_close_ao}/${data.AO_ID}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false
      ).then(data => {
        if (data.length) {
          setstatusClosingAO('INPROGRESS');
        }
      })
    }
  }, [data])

  const checkCloseAO = () => {
    request.fetchData(
      `${backend_url.check_close_ao}/${data.AO_ID}?todo_type=close_ao`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(data => {
      if (!data.length) {
        setstatusClosingAO('AVAILABLE')
      } else {
        setstatusClosingAO('INPROGRESS')
      }
      setModal(true)
    })
  }

  const handleSubmitCloseAO = (aoId) => {
    if (+aoId === data.AO_ID) {
      request.fetchData(
        `${backend_url.close_ao}?business_unit=${value_bu}&ao_id=${aoId}`,
        "POST",
        null,
        false,
        tokenBpxRequest,
      ).then((data) => {
        setstatusClosingAO('PROCESSING');
      })
    } else {
      SnackbarUtils.error(JSON.stringify("Wrong Accrual Object ID"));
    }
  }

  const disablesArrayCloseAO = ['ONHOLD', 'DELETED'];

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const onOpenDialog = () => setOpenDialog(true)
  const onCloseDialog = () => setOpenDialog(false)
  

  const onOpenRecalculate = ()=>setOpenRecalculate(true);
  const onCloseRecalculate = ()=>setOpenRecalculate(false);
  
  const handleSubmitRecalculate = (state)=>{
    const params = getParam(state).toString()
    request.fetchData(
      `${backend_url.recalculate_ao}?business_unit=${value_bu}&ao_id=${data.AO_ID}&${params}`,
      "POST",
      null,
      false,
      tokenBpxRequest,
    ).then((data) => {
      setOpenRecalculate(false)
      setIsCalculated(true)
    })
  }

  
  function handleAction (params, current_status, new_status) {
    setOpenAction(true)
    setActionCal({ ...params, current_status, new_status })

    if (params.AP_TYPE === "Accrual Closing" && data.AO_STATUS === "CLOSED") {
      request.fetchData(
        `${backend_url.accrual_object_header}/${params.AO_ID}/version/${Number(data.VERSION) - 1}?business_unit=${value_bu}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
      .then(data => {
        setPreviousStatus({
          status: data.AO_STATUS
        })
      })
      .catch(err => {
        
      })
    }
    

  }

  function handleCloseAction () {
    setOpenAction(false)
  }

  function handleActionRequest () {

    const newCalc = [...cancelCalculation, {
      status: actionCal.current_status, 
      date: new Date(),
      id: actionCal.RUN_ID
    }]
    localStorage.setItem('cancel_calculation', JSON.stringify(newCalc))
    setCancelCalculation(newCalc)
        
    request.fetchData(
      `${backend_url.accrual_posting_events_header_status}?business_unit=${value_bu}`,
      'PATCH',
      JSON.stringify(
        {
          "run_ids": [actionCal.RUN_ID],
          "action": "cancel"
        }
      ),
      false,
      tokenBpxRequest,
      false
    )
    .then((success) => {
      if (success === 204) {
        setOpenAction(false)
        setTrigger(true)
        SnackbarUtils.success(JSON.stringify("Status changed successfully"))
      }
      
    })
    .catch((err) => {
      SnackbarUtils.error(JSON.stringify("Status has not changed, try again"))
    })

  }


  const dialogAction = (
    <Dialog
      onClose={handleCloseAction}
      open={openAction}
      className="bpx-dialog-status-bg"
    >
      <div className='bpx-dialog-status-block'>
        <div className='bpx-dialog-status-header'>
          <div className='bpx-dialog-status-title'>
            Cancel Accrual Event
          </div>
          <div className='bpx-dialog-status-close'>
            <CloseIcon onClick={handleCloseAction} />
          </div>
        </div>
        
        {
        <div>
          <div className='bpx-dialog-status-content'>
              <div className='bpx-dialog-status-text'>
                Are you sure you want to Cancel <br />
                this Accrual Calculation Event?
              </div>
              {
                data.AO_STATUS === "CLOSED" && actionCal.AP_TYPE === "Accrual Closing" && (
                  <div>
                    <div className='bpx-dialog-status-content'>
                      <div className='bpx-dialog-status-text'>
                        ❗ <span style={{ color: '#AA0000' }}>Note: Cancellation of this Posting Event <br/>
                        will revert AO Status to: "{previousStatus.status}"</span>
                      </div>
                    </div>
                  </div>
                )
              }
          </div>
          
          <div className='bpx-dialog-status-footer'>
            <Button variant="outlined" size="small" onClick={handleCloseAction}>
              No
            </Button>
            <Button variant="contained" size="small" autoFocus onClick={handleActionRequest}>
              Confirm
            </Button>
          </div>
        </div>
        }        
        
      </div>
    </Dialog>
  )
    

  return (
    <>
      { openAction && dialogAction }

      <FlexibleTabs
        data={data}
        title={title}
        dataConvert={dataConvert}
        dataColumns={dataColumns}
        accordion={accordion}
        breadCrumb={breadCrumb}
        dataCloseAO={{
          isVisible: checkPermitionCloseAO,
          isDisable: !data.AO_STATUS || disablesArrayCloseAO.includes(data.AO_STATUS)
        }}
        isAccrualPosting={true}
        isRefreshBtn={checkRefreshRunPlan}
        handleClickCloseAO={checkCloseAO}
        handleClickDialog={onOpenDialog}
        handleClickChangeLog={onOpen}
        trigger={trigger}
        setTrigger={setTrigger}
        handleRecalculate={onOpenRecalculate}
      />

      <DialogRegenerate 
        open={openDialog}
        onCloseHandle={onCloseDialog}
        data={data}
      />

      <DialogChangeLogs
        open={open}
        onCloseHandle={onClose}
        dataLogs={dataLogs}
        data={data}
      />

      <ModalCloseAO
        aoId={data.AO_ID}
        activityId={data.ACTIVITY_ID}
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={(aoId) => handleSubmitCloseAO(aoId)}
        status={statusClosingAO}
      />

      <DialogRecalculateAO
        open={openRecalculate}
        onCloseHandle={onCloseRecalculate}
        handleSubmit={handleSubmitRecalculate}
        data={data}
        isCalculated={isCalculated}
      />

    </>
  )

}
