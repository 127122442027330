import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid } from '@mui/material'
import { CircularProgress, Typography} from '@mui/material'
import { numberFormatter, dateTimeFormatter} from "../../utils/utils"
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';
import { useTranslation } from "react-i18next"


export const SimulationResultsTable = ({adjustmentData}) => {

    const [rows, setRows] = useState()
    const [rows2, setRows2] = useState()
    const [pp1Total, setPP1Total] = useState()
    const [pp2Total, setPP2Total] = useState()
    const [amountDiff, setAmountDiff] = useState()
    const [total, setTotal] = useState()
    const { prefsData } = useUserPrefs()
    const { t } = useTranslation("translation")

    useEffect(() => {
        if (adjustmentData.postingData1 && adjustmentData.postingData2) {
            var rows1 = adjustmentData.postingData1.postings.map((posting) => {
                return ({
                    id: posting._id.BP_NR + "_" + posting._id.MATERIAL_NR + "_" + posting.accruals_td,
                    businessPartner1: posting._id.BP_NR,
                    material1: posting._id.MATERIAL_NR,
                    amount1: posting.accruals_td,
                    postingDate1: adjustmentData.postingData1.posting_date,
                    glaccount1: posting.GL_ACCOUNT

                })
            }
            )
            var rows2 = adjustmentData.postingData2 && adjustmentData.postingData2.postings.map((posting) =>
                ({
                    id: posting._id.BP_NR + "_" + posting._id.MATERIAL_NR + "_" + posting.accruals_td,
                    businessPartner2: posting._id.BP_NR,
                    material2: posting._id.MATERIAL_NR,
                    amount2: posting.accruals_td,
                    postingDate2: adjustmentData.postingData2.posting_date,
                    glaccount2: posting.GL_ACCOUNT
                })
            )
            var rows = rows1.map((e,i)=>{
                let temp = rows2.find(element => (element.businessPartner2 === e.businessPartner1 && element.material2 === e.material1))
                if (temp) {
                    let diff = e.amount1 + temp.amount2
                    return {...e, ...temp, amountDifference: diff}
                }
                return e
            })
            var amountDiff = rows.map((row) => {
                return {id: row.id, amountDifference: row.amountDifference}
            })
            setAmountDiff(amountDiff)
            setRows(rows1)
            setRows2(rows2)
            var pp1Total = 0
            var pp2Total = 0
            var total = 0
            rows.map(row => {
                pp1Total += parseFloat(row.amount1 ? row.amount1 : 0)
                pp2Total += parseFloat(row.amount2 ? row.amount2 : 0)
                total += parseFloat(row.amountDifference ? row.amountDifference : 0)
            })
            setPP1Total(pp1Total)
            setPP2Total(pp2Total)
            setTotal(total)
        }    
    }, [adjustmentData.postingData1, adjustmentData.postingData2])


    const columns = [
        { id: 'businessPartner1', label: t("Adjustments.MODAL.Business_Partner"), minWidth: 130 },
        { id: 'material1', label: t("Adjustments.MODAL.Material"), minWidth: 60 },
        {
          id: 'amount1',
          label: t("Adjustments.MODAL.Amount"),
          minWidth: 60,
          align: "right",
          format: (value) => value && numberFormatter(prefsData.decimal_notation).format(value),
        },
        {
          id: 'postingDate1',
          label: t("Adjustments.MODAL.Posting_Date"),
          minWidth: 110,
          format: (value) => value && dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, false, false),
        },
        {
          id: 'glaccount1',
          label: t("Adjustments.MODAL.glaccount"),
          minWidth: 100,
        },
    ];

    const columns2 = [
        { id: 'businessPartner2', label:t("Adjustments.MODAL.Business_Partner"), minWidth: 130 },
        { id: 'material2', label: t("Adjustments.MODAL.Material"), minWidth: 60 },
        {
            id: 'amount2',
            label: t("Adjustments.MODAL.Amount"),
            minWidth: 60,
            align: "right",
            format: (value) => value && numberFormatter(prefsData.decimal_notation).format(value),
        },
        {
            id: 'postingDate2',
            label: t("Adjustments.MODAL.Posting_Date"),
            minWidth: 110,
            format: (value) => value && dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, false, false),
        },
        {
            id: 'glaccount2',
            label: t("Adjustments.MODAL.glaccount"),
            minWidth: 100,
        },
    ]

    const columns3 = [
        {
            id: 'amountDifference',
            label: t("Adjustments.MODAL.Amount_Difference"),
            minWidth: 70,
            align: "right",
            format: (value) => value && numberFormatter(prefsData.decimal_notation).format(value),
        },
    ]

    return (
        <>
            <Grid container spacing={3} sx={{overflowY: 'scroll',  "&::-webkit-scrollbar": {visibility : "hidden"}}}>
                <Grid item xs={5}>
                    <Typography sx={{fontWeight: "700"}} variant="body2">
                        1st Postings Period Total
                    </Typography>
                    <hr/>
                </Grid>
                <Grid item xs={5}>
                    <Typography sx={{fontWeight: "700"}} variant="body2">
                        2nd Postings Period Total
                    </Typography>
                    <hr/>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} spacing={3}  sx={{maxHeight: 450, overflowY: 'scroll'}}>
                <Grid item xs={5}>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 500}} size="small">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                className='table-head-dark-mode'
                                                key={column.id}
                                                align={column.align}
                                                style={{ fontSize: "12px", top: 57, minWidth: column.minWidth, backgroundColor: '#f1f1f1' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {rows ?
                                    <TableBody>
                                        {rows.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell sx={{fontSize: "12px"}} key={column.id} align={column.align}>
                                                                {column.format
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan="10">
                                                <CircularProgress sx={{postion: "absolute", margin: 10, alignSelf: "center"}}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 500}} size="small">
                                <TableHead>
                                    <TableRow>
                                        {columns2.map((column) => (
                                            <TableCell
                                                className='table-head-dark-mode'
                                                key={column.id}
                                                align={column.align}
                                                style={{ fontSize: "12px", top: 57, minWidth: column.minWidth, backgroundColor: '#f1f1f1' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {rows2 ?
                                    <TableBody>
                                        {rows2.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns2.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell sx={{fontSize: "12px"}} key={column.id} align={column.align}>
                                                                {column.format
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan="10">
                                                <CircularProgress sx={{postion: "absolute", margin: 10, alignSelf: "center"}}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                <Paper sx={{ width: '80%' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {columns3.map((column) => (
                                        <TableCell
                                            className='table-head-dark-mode'
                                            key={column.id}
                                            align={column.align}
                                            style={{ fontSize: "12px", top: 57, minWidth: column.minWidth, backgroundColor: '#f1f1f1' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {amountDiff ?
                                <TableBody>
                                    {amountDiff.map((row, i) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id + "_" + i}>
                                                {columns3.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell sx={{fontSize: "12px"}} key={column.id} align={column.align}>
                                                            {column.format
                                                            ? column.format(value)
                                                            : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan="10">
                                            <CircularProgress sx={{postion: "absolute", margin: 10, alignSelf: "center"}}/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    </Paper>
                </Grid>
               
            </Grid>
            <Grid container rowSpacing={1} spacing={3} sx={{overflowY: 'scroll',  "&::-webkit-scrollbar": {visibility : "hidden"}}}>
                <Grid item xs={5}>
                    <p className='adjustment-summary'>
                        1st Postings Period Total &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <strong>{pp1Total}</strong>
                    </p>
                </Grid>
                <Grid item xs={5}>
                    <p className='adjustment-summary'>
                        2nd Postings Period Total &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <strong>{pp2Total}</strong>
                    </p>
                </Grid>
                <Grid item xs={2}>
                    <p className='adjustment-summary'>
                        Total Difference &emsp;&emsp;<strong>{total}</strong>
                    </p>
                </Grid>
            </Grid>
        </>
  );
}
